import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {withTheme} from "../../../../themes";
import ToggleFilters from './ToggleFilters'
import {Wrapper, SearchWrapper, Toggle, SearchField, User} from "./styled";
import { useSelector } from "react-redux";
import { getLayout } from "./../../../../store/settings/selectors";

import UserTag from "./../../../User/UserTag";
import {LAYOUT_XS, LAYOUT_SM} from './../../../../settings';

const Search = ({mobile = false, theme, state, placeholder, onChange, value, isFilterActive, onToggleFilters, filters, hideToggleFilters = false}) => {

  const { search } = theme.list;
  const layout = useSelector(getLayout);

  return (
    <Wrapper mobile={mobile}>
      <SearchWrapper background={theme.list.search.background} borderRadius={theme.list.search.borderRadius}>
        <SearchField
          onChange={onChange}
          placeholder={placeholder}
          textColor={theme.list.search.textColor}
          placeholderColor={theme.list.search.placeholderColor}
        />
      </SearchWrapper>
      {!hideToggleFilters ? (
        <Toggle layout={layout}>
          <ToggleFilters
            onClick={onToggleFilters}
            iconBackground={!isFilterActive ? search.icon.normal.background: search.icon.active.background}
            iconColor={!isFilterActive ? search.icon.normal.color : search.icon.active.color}
            icon={theme.icons.set.openFilters}
            showSticker={isFilterActive}
            borderRadius={theme.list.search.borderRadius}
          />
        </Toggle>
      ) : null}
      {layout === LAYOUT_XS || layout === LAYOUT_SM ?
        <User>
          <UserTag
            color={theme.topbar.textColor}
            buttonVariant={theme.topbar.buttonVariant}
            size={{"value": 46, "unit": "px"}}
          />
        </User>
      : null }
    </Wrapper>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isFilterActive: PropTypes.bool
};

export default React.memo(withTheme(Search));
