import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useDispatch, useSelector} from "react-redux";
import { getLayout, getConfig } from "./../../../store/settings/selectors";
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import {toggleFilters} from "./../../../store/settings/actions";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import Logo from './../../../components/App/Logo';
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Image from './../../../components/App/Image';
import Button from "./../../../components/App/Button";

import heroLogo from './logo.svg';
import heroBg from './testata.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  text-align: center;
`;



const LogoWrapper = styled.div`
  max-width: 90%;
`;

const Hero = styled.div`
  width: 100%;
  position: relative;
  padding: 60px 30px;
  background-image: url('${props => props.background}');
  background-color: #000000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-top: -5px;
`;

const HeroText = styled.div`
  font-size: ${props => props.mobile ? 20 : 38}px;
  line-height: ${props => props.mobile ? 30 : 52}px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  max-width: 300px;

  text-align: ${props => props.mobile ? "center" : "left"};
`;
const HeroTitle = styled.div`
  font-weight: 900;
`;


export default function Home(){

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const config = useSelector(getConfig);


  return (
    <Wrapper>
      <MainContainer>
        <Hero background={heroBg} id="hero">
          <HeroText mobile={layout === LAYOUT_XS || layout === LAYOUT_SM}>
            <Image src={heroLogo} width={{"value": 200, "unit": "px"}}/>
            <br/><br/>
            {translations["homepage"]["_home-text"]}
            <br/>
            <HeroTitle>
              {translations["homepage"]["_home-title"]}
            </HeroTitle>
            <br/>
            <a target="_blank" href={"https://catalogo.asphaltica.it/"}><Button>{translations["homepage"]["_home-cta"]}</Button></a>
          </HeroText>
        </Hero>
      </MainContainer>
    </Wrapper>

  );

}
