import React, { PureComponent } from 'react';
import styled from "styled-components";
import spinner from '../../../assets/loader.gif';
import {compose} from "redux";
import {withTheme} from "../../../themes";
import {connect} from "react-redux";

const Wrapper = styled.form`
  width: 100%;
`;

const Input = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  background: #EDEDED;
  border: none;
  border-radius: 10px;
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 15px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  border: 1px solid ${({color}) => color};
  width: 100px;
  height: 33px;
  background-image: ${props => props.isLoading ? 'url(' + spinner + ')' : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 16px auto;
  text-indent: ${props => props.isLoading ? '-9999px' : '0'};
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  color: ${({color}) => color};
  ${props => props.disabled ? 'opacity: 0.5;' : ''}
`;

const Waiting = styled.div`
  text-align: center;
`;

const BtnElimina = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  border-bottom: 2px solid #000;
  height: auto;
  padding: 0;
  margin-right: 20px;
  top: -3px;
  position: relative;
  cursor: pointer;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class Textarea extends PureComponent{

  state = {
    value: ''
  };

  changeHandler = (e) => {
    if(e.keyCode === 13 && !e.shiftKey) {
      this.submitHandler(e);
    } else {
      this.setState({
        value: e.target.value
      })
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.props.onSend(this.state.value);
    this.setState({
      value: ''
    });
    this.formRef.reset();
  };

  render() {

    const { isRoom, isSending, onArchive, placeholder, sendLabel = 'Invia', closeRoomLabel = 'Archivia', theme } = this.props;
    const { value } = this.state;

    return (
      <Wrapper onSubmit={this.submitHandler} ref={ref => this.formRef = ref}>
        <Input onKeyUp={this.changeHandler} placeholder={placeholder} />
        <Right>
          <div>
            {isRoom ? <BtnElimina type="button" onClick={onArchive}>{closeRoomLabel}</BtnElimina> : null}
          </div>
          <Button color={theme.colors.primary} isLoading={isSending} type="submit" disabled={isSending || value.trim() === ''}>{sendLabel}</Button>
        </Right>
      </Wrapper>
    );

  }

}

export default compose(
  withTheme
)(Textarea);
