import React, { useRef, useEffect } from 'react';
import styled from "styled-components";
import Message from "./Message";
import {useSelector} from "react-redux";
import {getMessages} from "../../../store/chat/selectors";

/* eslint-disable no-unused-expressions */

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  display: flex;
  overflow: scroll;
  padding: 25px;
  flex-direction: column;
`;

export default function Messages({ items = [] }) {

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  };

  useEffect(() => {
    scrollToBottom()
  }, [items]);

  return (
    <Wrapper>
      {items.map((item, index) => <Message key={`chat-message-${index}`} {...item} />)}
      <div ref={messagesEndRef} />
    </Wrapper>
  );
}

