import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLocale, getTranslations} from "../../store/localization/selectors";
import { getConfig } from "./../../store/settings/selectors";

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  background: #fff;
  padding: 20px;
  border: 1px solid rgb(237,237,237);
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

function Terms() {

  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const config = useSelector(getConfig);
// console.log(translations['terms']);
// console.log(translations['terms']['legal_area']);
  return (
    <Container>
      {config.tenant === "VIR" ?
        <>
          {lang === "it" ?
            <>
              <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
              <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
              <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
              <p><strong>Condizioni generali di contratto</strong></p>
              <p>Prendi visione delle <a href="https://vrf-app.firebaseapp.com/usage-conditions-vinitaly-it.html">condizioni generali di contratto</a> che disciplinano l'uso di Vinitaly Plus&nbsp;</p>
              <p><strong>Privacy</strong></p>
              <p>Prendi visione dei documenti privacy relativi al Vinitaly Plus:</p>
              <p>-<a href="https://vrf-app.firebaseapp.com/privacy-policy-vinitaly-it.html">informativa privacy Vinitaly Plus</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione al Vinitaly Plus e per il suo utilizzo;</p>
              <p>-<a href="https://vinitaly.com/it/verona/cookie/">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
              <p>-informativa privacy APP riguardante il trattamento dei dati forniti o generati usando l&rsquo;app del Vinitaly.</p>
            </>
          :
            <>
              <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
              <p><strong>Identity and contact data of the service provider</strong></p>
              <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
              <p><strong>Terms</strong><strong> &amp; </strong><strong>conditions</strong></p>
              <p>Read the <a href="https://vrf-app.firebaseapp.com/usage-conditions-vinitaly-en.html">general terms and conditions</a> that regulate the use of the Vinitaly Plus</p>
              <p><strong>Privacy</strong></p>
              <p>Read the privacy documents related to the Vinitaly Plus:<br/><br/>- <a href="https://vrf-app.firebaseapp.com/privacy-policy-vinitaly-en.html">privacy notice Vinitaly Plus</a> regarding the processing of personal data provided for the Vinitaly Plus subscription and use,<br/>- <a href="https://vinitaly.com/en/verona/cookie">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/>- privacy notice APP concerning the processing of data provided or generated using the Vinitaly App.</p>
            </>
          }
        </>
      : config.tenant === "MDM" ?
      <>
        {lang === "it" ?
          <>
            <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
            <p><strong>Condizioni generali di contratto</strong></p>
            <p>Prendi visione delle <a target="_blank" href="https://vrf-app.firebaseapp.com/usage-conditions-marmomac-it.html">condizioni generali di contratto</a> che disciplinano l'uso di Marmomac Plus&nbsp;</p>
            <p><strong>Privacy</strong></p>
            <p>Prendi visione dei documenti privacy relativi al Marmomac Plus:</p>
            <p>-<a target="_blank" href="https://vrf-app.firebaseapp.com/privacy-policy-marmomac-it.html">informativa privacy Marmomac Plus</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Marmomac Plus e per il suo utilizzo;</p>
            <p>-<a target="_blank" href="https://www.marmomac.com/privacy-e-cookie-policy/">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
            <p>-informativa privacy APP riguardante il trattamento dei dati forniti o generati usando l&rsquo;App Marmomac.</p>
          </>
        :
          <>
            <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identity and contact data of the service provider</strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
            <p><strong>Terms</strong><strong> &amp; </strong><strong>conditions</strong></p>
            <p>Read the <a href="https://vrf-app.firebaseapp.com/usage-conditions-marmomac-en.html">general terms and conditions</a> that regulate the use of the Marmomac Plus</p>
            <p><strong>Privacy</strong></p>
            <p>Read the privacy documents related to the Marmomac Plus:<br/><br/>- <a href="https://vrf-app.firebaseapp.com/privacy-policy-marmomac-en.html">privacy notice Marmomac Plus</a> regarding the processing of personal data provided for the Marmomac Plus subscription and use,<br/>- <a href="https://www.marmomac.com/en/privacy-and-cookie-policy/">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/>- privacy notice APP concerning the processing of data provided or generated using the App.</p>
          </>
        }
      </>
      : config.tenant === "AGR" ?
      <>
        {lang === "it" ?
          <>
            <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Prendi visione dei documenti privacy relativi a Fieragricola Plus:</p>
            <p>-<a target="_blank" href="https://catalogo.fieragricola.it/it/contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Fieragricola Plus e per il suo utilizzo;</p>
            <p>-<a target="_blank" href="https://www.fieragricola.it/it/privacy-e-cookie-policy">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
          </>
        :
          <>
            <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identity and contact data of the service provider</strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Read the privacy documents related to the Fieragricola Plus:<br/><br/>- <a href="https://catalogo.fieragricola.it/en/contact-policy">privacy notice Fieragricola Plus</a> regarding the processing of personal data provided for the Fieragricola Plus subscription and use,<br/>- <a href="https://www.fieragricola.it/en/privacy-and-cookie-policy">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/></p>
          </>
        }
      </>
      : config.tenant === "FIC" ?
      <>
        {lang === "it" ?
          <>
            <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Prendi visione dei documenti privacy relativi a Fieracavalli Plus:</p>
            <p>-<a href="contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Fieracavalli Plus e per il suo utilizzo;</p>
            <p>-<a target="_blank" href="https://fieracavalli.it/it/privacy-policy-siti-internet/">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
          </>
        :
          <>
            <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identity and contact data of the service provider</strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Read the privacy documents related to the Fieracavalli Plus:<br/><br/>- <a href="contact-policy">privacy notice Fieracavalli Plus</a> regarding the processing of personal data provided for the Fieracavalli Plus subscription and use,<br/>- <a href="https://fieracavalli.it/en/privacy-policy-websites/">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/></p>
          </>
        }
      </>
      :config.tenant === "SAM" ?
      <>
        {lang === "it" ?
          <>
            <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Prendi visione dei documenti privacy relativi a Samoter Plus:</p>
            <p>-<a href="contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Samoter Plus e per il suo utilizzo;</p>
            <p>-<a target="_blank" href="https://www.samoter.it/application/files/5016/4459/7610/info_customer_care_-_ITA.pdf">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
          </>
        :
          <>
            <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identity and contact data of the service provider</strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Read the privacy documents related to the Samoter Plus:<br/><br/>- <a href="contact-policy">privacy notice Samoter Plus</a> regarding the processing of personal data provided for the Samoter Plus subscription and use,<br/>- <a href="https://www.samoter.it/application/files/3616/4500/2591/info_customer_care_-_ENG.pdf">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/></p>
          </>
        }
      </>
      : null }
{/*      <div dangerouslySetInnerHTML={{__html: translations['terms']['legal_area']}}></div>*/}

    </Container>
  );

}

export default Terms;
