import React from 'react';
import styled from 'styled-components';
import Search from '../List/Sidebar/Search/Search';
import ChatResult from "../../tenants/VIR/Chat/ChatResult";
import {useDispatch, useSelector} from "react-redux";
import {
  getActiveRooms,
  getActiveTab,
  getClosedRooms,
  getCurrent,
  getPendingRooms,
  getSearchValue,
  getIsAgent,
} from "../../store/chat/selectors";
import {search, setCurrent, setTab} from "../../store/chat/actions";
import SidebarTabs from "./components/SidebarTabs";
import {getTranslations} from "../../store/localization/selectors";
import Analytics from "../Analytics/Analytics";

const Container = styled.div`
  width: ${props => props.mobile ? '100%' : 'calc(100% - 30px)'};
  margin: ${props => props.mobile ? '0' : '0px 15px'};
`;
const Wrapper = styled.div`
  position: relative;
  padding-left: ${props => props.mobile ? '0' : '50%'};
  padding-top: ${props => props.mobile ? '2px' : '25px'};
  display: flex;
  flex-direction: column;
  height: ${props => props.mobile ? 'calc(100vh - 38px)' : '100%'};
  overflow: hidden;
  width: 100%;
`;

const Subtitle = styled.div`
  padding: 5px 25px;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
`;

const ResultsList = styled.div`
  flex: 1;
  overflow: scroll;
`;

export default function Sidebar({mobile = false}) {

  const dispatch = useDispatch();
  const rooms = useSelector(getActiveRooms);
  const roomsClosed = useSelector(getClosedRooms);
  const roomsPending = useSelector(getPendingRooms);
  const current = useSelector(getCurrent);
  const isAttendeeAgent = useSelector(getIsAgent);
  const activeTab = useSelector(getActiveTab);
  const searchValue = useSelector(getSearchValue);
  const translations = useSelector(getTranslations);

  function roomClickHandler(payload) {
    dispatch(setCurrent(payload))
  }

  function tabClickHandler(tab) {
    dispatch(setTab(tab))
  }

  function searchHandler(value) {
    dispatch(search(value));
  }

  const counts = {
    active: rooms.length,
    archived: roomsClosed.length,
    pending: roomsPending.length
  };

  const tabsLabels = {
    active: translations['chat']['ActiveStatus'],
    pending: translations['chat']['WaitingStatus'],
    archived: translations['chat']['ClosedStatus']
  };

  return (
    <Wrapper mobile={mobile}>
      <Search
        mobile={mobile}
        hideToggleFilters={true}
        placeholder={translations['UI']['Search']}
        value={searchValue}
        onChange={e => searchHandler(e.target.value)}
      />
      <SidebarTabs mobile={mobile} onTabClick={tabClickHandler} current={activeTab} counts={counts}
                   labels={tabsLabels}/>
      <ResultsList>
        <Container mobile={mobile}>
          {activeTab === 'pending' ? roomsPending.map((room, index) => {
            const isCurrent = current ? !room.hasOwnProperty('roomId') && ((isAttendeeAgent ? room.userId : room.companyId) === current.id) : false;
            return <ChatResult showUnreadCount={false} isAgent={isAttendeeAgent} active={isCurrent}
                               key={`chat-list-pending-${index}`} onClick={roomClickHandler} {...room} />;
          }) : null}
          {activeTab === 'active' ? rooms.map((room, index) => {
            const isCurrent = current ? (room.hasOwnProperty('roomId') && room.roomId === current.id) : false;
            return <ChatResult showUnreadCount={true} isAgent={isAttendeeAgent} active={isCurrent}
                               key={`chat-list-${index}`} onClick={roomClickHandler} {...room} />;
          }) : null}
          {activeTab === 'archived' ? roomsClosed.map((room, index) => {
            const isCurrent = current ? (room.hasOwnProperty('roomId') && room.roomId === current.id) : false;
            return <ChatResult showUnreadCount={false} isAgent={isAttendeeAgent} active={isCurrent}
                               key={`chat-list-closed-${index}`} onClick={roomClickHandler} {...room} />;
          }) : null}
        </Container>
      </ResultsList>
      <Analytics name="chat"/>
    </Wrapper>
  )
};
