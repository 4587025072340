import React, { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import {connect} from 'react-redux';
import {compose} from "redux";
import LevelButton from './LevelButton';
import { LevelButtonContainer } from "./styled";
import {getTranslations} from "./../../../store/localization/selectors";
import styled from 'styled-components';
import { withTheme } from "../../../themes";


const Input  = styled.input`
  color: ${props => props.color} !important;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.color} !important;
  }
  :-ms-input-placeholder {
     color: ${props => props.color} !important;
  }
`;

const mapStateToProps = (state) => ({
  translations: getTranslations(state)
})

const List = ({ theme, data, field, onClick, active, selected, onSearch, getInputRef, searchable, translations }) => {

  const [inputValue, setInputValue] = useState('');

  const debouncedSave = useCallback(
    debounce((newValue) => onSearch(newValue), 300),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  return (
    <div className={'List'}>
      {searchable && (
        <div className={'List__Search'}>
          <Input
            ref={ref => getInputRef(ref)}
            color={theme.filters.search.textColor}
            value={inputValue}
            className={'List__SearchInput'}
            type='search'
            placeholder={translations["UI"]["Search"]}
            onChange={(input) => updateValue(input.target.value)}
            onKeyUp={(input) => updateValue(input.target.value)}
          />
        </div>
      )}
      <div className={'List__Content'}>
        {data.map((item, index) => (
          <LevelButtonContainer key={`filter-${field}-${index}`} >
            <LevelButton active={selected && selected.indexOf(item.encodedKey) !== -1} hideArrow={true} onClick={e => onClick(item.encodedKey)} label={item.label} count={item.doc_count} />
          </LevelButtonContainer>
        ))}
      </div>
    </div>
  );
};


export default compose(
  connect(mapStateToProps)
)(withTheme(List));
