import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { withTheme } from './../../themes';
import { getLayout, getIsFullModalOpen, getFullModalContent } from './../../store/settings/selectors';
import { hideFullModal } from "./../../store/settings/actions";

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

import Icon from "./../App/Icon";
import Button from "./../App/Button";

const Wrapper  = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #0000004D;
  z-index: 999999;
  display: ${props => props.isOpen ? "flex" : "none"};
  justify-content: space-around;
  align-items: center

`;
const Content  = styled.div`
  position: relative;
  width: 1200px;
  max-width: 90%;
  height: 80%;
  border: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  box-shadow: ${props => props.boxShadow};
  background: ${props => props.background};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};


  overflow: hidden;
`;
const Close  = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #dedede;
  background-color: #dedede;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
`;


const FullModal = ({
  variant = "default",
  theme
}) => {
  const layout = useSelector(getLayout);
  const isOpen = useSelector(getIsFullModalOpen);
  const content = useSelector(getFullModalContent);
  const dispatch = useDispatch();
// console.log(tags);


  return (
    <Wrapper isOpen={isOpen}>
      <Content
        borderStyle={theme.fullModal.borderStyle}
        borderSize={theme.fullModal.borderSize}
        borderColor={theme.fullModal.variant[variant].borderColor}
        borderRadius={theme.fullModal.borderRadius}
        boxShadow={theme.fullModal.variant[variant].boxShadow}
        background={theme.fullModal.variant[variant].background}
        padding={theme.post.padding[layout]}
      >
        <Close onClick={() => dispatch(hideFullModal())}>
          <Icon name={"close-1"} color={"#585858"} size={{value: 30, unit: 'px'}} />
        </Close>
        {content}
      </Content>
    </Wrapper>
  )
}
export default withTheme(FullModal)
