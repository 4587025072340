import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'

import {LAYOUT_XS, LAYOUT_SM} from './../../settings'
import Analytics from '../Analytics/Analytics';
import {DOMAINS} from "./../../domains";

// import Favorites from "./../../components/Idle/Favorites"

import {compose} from "redux";
import {withRouter} from "react-router";
import {withTheme} from "../../themes";

import {setCount as companiesSetCount} from "../../store/companies/actions";
import {setCount as productsSetCount} from "../../store/products/actions";
import {setCount as eventsSetCount} from "../../store/events/actions";
import {setCount as casehistoriesSetCount} from "../../store/casehistories/actions";

import DefautIdlepage from './Default';
import VirIdlepage from '../../tenants/VIR/Idlepage';
import MdmIdlepage from '../../tenants/MDM/Idlepage';
import AgrIdlepage from '../../tenants/AGR/Idlepage';
import FicIdlepage from '../../tenants/FIC/Idlepage';
import SamIdlepage from '../../tenants/SAM/Idlepage';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const mapStateToProps = (state) => ({
  config: state.settings.config,
  layout: state.settings.layout,
  listType: state.settings.resultsType,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

class Idlepage extends Component {

  constructor(props) {
    super(props);
    this.props = props;
    // console.log(props.area);
  }


  componentDidMount() {
    if(DOMAINS[window.location.hostname].areas["companies"].enable){
      this.props.dispatch(companiesSetCount());
    }
    if(DOMAINS[window.location.hostname].areas["products"].enable){
      this.props.dispatch(productsSetCount());
    }
    if(DOMAINS[window.location.hostname].areas["events"].enable){
      this.props.dispatch(eventsSetCount());
    }
    if(DOMAINS[window.location.hostname].areas["casehistories"].enable){
      this.props.dispatch(casehistoriesSetCount());
    }

  }

  renderContent() {

    const {tenant} = this.props.config;

    const props = {
      config: this.props.config,
      area: this.props.area,
    };

    switch (tenant) {
      case "VIR":
        return <VirIdlepage {...props} />;
      case "MDM":
        return <MdmIdlepage {...props} />;
      case "AGR":
        return <AgrIdlepage {...props} />;
      case "FIC":
        return <FicIdlepage {...props} />;
      case "SAM":
        return <SamIdlepage {...props} />;
      default:
        return <DefautIdlepage/>;

    }

  }

  render() {
    const {area, analytics = true, listType} = this.props;

    return (
      <Wrapper>
        {this.renderContent()}
        {analytics ? <Analytics key={`${area}-${listType}`} name={area} listType={listType} /> : null}
      </Wrapper>
    );
  }

}

export default compose(
withRouter,
withTheme,
connect(mapStateToProps, mapDispatchToProps)
)(Idlepage);
