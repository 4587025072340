import React from 'react';
import styled from 'styled-components'
import Messages from "./Messages";
import Textarea from "./Textrea";
import spinner from '../../../assets/loader.gif';

import Image from './../../App/Image';
import Spacer from './../../App/Spacer';

const Wrapper = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  border-radius: 10px 10px 0 0;
  background: #fff;
  border: 1px solid #EDEDED;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const CompanyLogo = styled.div`
  width: 150px;
  height: 50px;
  background: #000;
`;

const Inner = styled.div`
  background: #fff;
  border: 1px solid #EDEDED;
  flex: 1;
  display: flex;
  position: relative;
`;

const Footer = styled.div`
  border-radius: 0 0 10px 10px;
  background: #fff;
  border: 1px solid #EDEDED;
  padding: 30px 25px;
  display: flex;
  justify-content: center;
  margin-top: 1px
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  background: url(${spinner}) center no-repeat;
  margin: 0 auto;
`;

export default function ChatContainer({ companyData, data, onSend = () => null, isLoadingMessages = true, disabledSending = false, messages = [] }) {

  return (
    <Wrapper>
      <Header>
        { true ?
          <>
            {companyData ?
              (companyData.logo ?
                <Image height={{"value": 60, "unit": "px"}} fit="contain" src={companyData.logo} alt="logo"/>
              :
                <Title>{data.companyName}</Title>
              )
            :
              <Spacer size={{"value": 60, "unit": "px"}} />
            }

            {data.agentNick}
          </>
        :
          <>
            <Title>{data.companyName}</Title>
            {data.agentNick}
          </>
        }
      </Header>
      <Inner>
        {isLoadingMessages ? <Loader /> : <Messages items={messages} />}
      </Inner>
      <Footer>
        <Textarea disabledSending={disabledSending} onSend={onSend} isSending={false} />
      </Footer>
    </Wrapper>
  );

}
