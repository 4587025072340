import React from 'react';
import styled from 'styled-components'
import Messages from "./Messages";
import Textarea from "./Textarea";
import spinner from '../../../assets/loader.gif';
import Button from "../../App/Button";
import Icon from "../../App/Icon";
import {useTheme} from "../../../themes";
import {useDispatch, useSelector} from "react-redux";
import {closeChat} from "../../../store/chat/actions";
import {getTranslations} from "../../../store/localization/selectors";
import {chatReadableDate, getDateFormat} from "../../../helpers";

const Wrapper = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  margin: ${props => props.mobile ? '10px auto' : '0'};
  height: ${props => props.mobile ? 'auto' : 'calc(100vh - 108px)'};
  min-height: 350px;
`;

const Header = styled.div`
  border-radius: 10px 10px 0 0;
  background: #fff;
  border: 1px solid #EDEDED;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-bottom: 1px;
  text-align: center;
  
  > div:first-child,
  > div:last-child {
    width: 30px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const CompanyLogo = styled.div`
  width: 150px;
  height: 50px;
  background: #000;
`;

const Inner = styled.div`
  background: #fff;
  border: 1px solid #EDEDED;
  flex: 1;
  display: flex;
  position: relative;
  min-height: 150px;
`;

const Footer = styled.div`
  border-radius: 0 0 10px 10px;
  background: #fff;
  border: 1px solid #EDEDED;
  padding: 30px 25px;
  margin-top: 1px;
  text-align: center;
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  background: url(${spinner}) center no-repeat;
  margin: 0 auto;
`;

const BtnElimina = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  border-bottom: 2px solid #000;
  height: auto;
  padding: 0;
  margin-right: 20px;
  top: -3px;
  position: relative;
  cursor: pointer;
`

const BackButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

export default function Container({ room, attendeeId, isAgent = false, onSend = () => null, onArchiveRoom = () => null, onStartRoom = () => null, isLoadingMessages = true, messages = [], isMobile = false }) {

  const theme = useTheme();
  const dispatch = useDispatch();
  const translations = useSelector(getTranslations);

  function goBack() {
    dispatch(closeChat());
  }

  const archivedChat = `${translations['chat']['ClosedRoom'] ? translations['chat']['ClosedRoom']: 'La chat è stata archiviata il'} ${getDateFormat(room.closeTimestamp)}` ;
  const waitingChat = translations['chat']['WaitingRoom'] ? translations['chat']['WaitingRoom'] : "Prima di inviare un nuovo messaggio devi aspettare che l'azienda risponda.";

  return (
    <Wrapper mobile={isMobile}>
      <Header>
        <div>
          {isMobile ? (
            <BackButton onClick={goBack}>
              <Icon
                size={{
                  value: 30,
                  unit: "px"
                }}
                name={theme.icons.set.menuitemPrev}
                color={theme.mobileheader.icon.color}
                background={theme.mobileheader.icon.background}
              />
            </BackButton>
          ) : null}
        </div>
        <div>
          <Title>{room.label}</Title>
          {!isAgent ? room.agentNickname : null}
        </div>
        <div />
      </Header>
      <Inner>
        {isLoadingMessages ? <Loader /> : <Messages isAgent={isAgent} attendeeId={attendeeId} items={messages} />}
      </Inner>
      <Footer>
        {(!isAgent && !room.hasOwnProperty('roomId') && !room.hasOwnProperty('message')) || (room.hasOwnProperty('roomId') && room.hasOwnProperty('roomStatus') && room.roomStatus !== 'CLOSED') ? (
          <Textarea
            placeholder={translations['chat']['TextareaPlaceholder']}
            sendLabel={translations['chat']['Send']}
            closeRoomLabel={translations['chat']['DeleteChat']}
            isAgent={isAgent}
            isRoom={room.hasOwnProperty('roomId')}
            onArchive={onArchiveRoom}
            onSend={onSend}
            isSending={false}
          />
        ) : null}
        {!room.hasOwnProperty('roomId') && isAgent ? (
          <>
            <BtnElimina onClick={onArchiveRoom}>{translations['chat']['DeleteChat']}</BtnElimina>
            <Button onClick={onStartRoom} display={'inline-block'} variant="default-o">{translations['chat']['BeginChat']}</Button>
          </>
        ) : null}
        {room.hasOwnProperty('roomStatus') && room.roomStatus === 'CLOSED' ? <div>{archivedChat}</div> : null}
        {!isAgent && room.hasOwnProperty('message') ? <div>{waitingChat}</div> : null}
      </Footer>
    </Wrapper>
  );

}
