import React, {Component} from 'react';
import InputRange from 'react-input-range';
import './style.scss';

export default class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    const {minValue, maxValue} = this.props;

    this.minpos = minValue;
    this.maxpos = maxValue;
    this.minval = Math.log(minValue);
    this.maxval = Math.log(maxValue);

    let defaultValue = {
      min: minValue,
      max: maxValue
    };

    this.scale = (this.maxval - this.minval) / (this.maxpos - this.minpos);

    if (this.props.value) {

      const values = this.props.value.split(' - ');
      const minPosition = this.minpos + (Math.log(values[0]) - this.minval) / this.scale;
      const maxPosition = this.minpos + (Math.log(values[1]) - this.minval) / this.scale;

      defaultValue = {
        min: minPosition,
        max: maxPosition
      }

    }

    this.state = {
      value: defaultValue
    };
  }

  componentDidUpdate(prevProps) {

    if (prevProps.value && !this.props.value) {
      const {minValue, maxValue} = this.props;

      this.setState({
        value: {
          min: minValue,
          max: maxValue
        }
      })
    }

  }

  getValue(value) {
// console.log(value);
// console.log(((value - this.minpos)));
// console.log(Math.exp((value - this.minpos)));
// console.log(Math.exp((value - this.minpos) * this.scale + this.minval));
    // value = this.props.useLogarithmic ? Math.exp((value - this.minpos) * this.scale + this.minval) : value;
    return value;

  }

  handleChange(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      value = {min: this.getValue(value.min).toFixed(0), max: this.getValue(value.max).toFixed(0)}
      this.props.onChange([value.min, value.max]);
    }, 500)

  }

  render() {

    const {minValue, maxValue} = this.props;

    return (
      <div className='RangeSlider_Container'>
        <InputRange
          maxValue={maxValue}
          minValue={minValue}
          value={this.state.value}
          formatLabel={value => `${this.getValue(value).toFixed(0)}`}
          onChange={value => {
            this.handleChange(value);
            this.setState({value})
          }}/>
      </div>
    );
  }
}
