import React, {useEffect} from 'react';
import ChatContainer from "./components/ChatContainer";
import styled from "styled-components";
import {connect, useDispatch, useSelector} from "react-redux";
import {archiveRoom, sendMessage, setCurrent, startRoom} from "../../store/chat/actions";
import {
  getAttendee, getCompanyData,
  getCurrentRoom,
  getIsAgent, getIsLoadingRooms,
  getMessages,
  isLoadingMessages,
  isSending
} from "../../store/chat/selectors";
import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from './../../settings';
import { toggleFilters } from "../../store/settings/actions";

import Container from './../App/Container';
import Spacer from './../App/Spacer';
import {getConfig, getIfMobile, getLayout} from "../../store/settings/selectors";
import {getLocale, getTranslations} from "../../store/localization/selectors";
import UserInfo from "./components/UserInfo";
import CompanyInfo from "./components/CompanyInfo";
import Sidebar from "./Sidebar";
import MobileHeader from "../Common/MobileHeader";
import {getByEmail as getBCardByEmail} from "../../store/bcard/selectors";
import {useTheme} from "../../themes";

const Alert = styled.div`
  width: 100%;
  max-width: 660px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #EDEDED;
  padding: 25px;
  height: 70px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (!props.mobile) ? 12 : 0}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;

export default function Chat() {

  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(toggleFilters(false));
    return () => dispatch(setCurrent({}));
  }, []);

  const currentRoom = useSelector(getCurrentRoom);
  const attendee = useSelector(getAttendee);
  const loadingMessages = useSelector(isLoadingMessages);
  const messageIsSending = useSelector(isSending);
  const messages = useSelector(getMessages);
  const isAttendeeAgent = useSelector(getIsAgent);
  const config = useSelector(getConfig);
  const layout = useSelector(getLayout);
  const lang = useSelector(getLocale);
  const translations = useSelector(getTranslations);
  const isLoadingRooms = useSelector(getIsLoadingRooms);
  const isMobile = useSelector(getIfMobile);

  const currentCompanyData = useSelector(getCompanyData(currentRoom ? currentRoom.companyId : null));
  let currentBCard = useSelector(getBCardByEmail(currentRoom ? (isAttendeeAgent ? currentRoom.userId : currentRoom.agentId) : null));
  currentBCard = isAttendeeAgent && currentRoom && !currentRoom.hasOwnProperty('roomId') ? {
    nickname: currentRoom.moreInfo?.userNickname,
    name: currentRoom.moreInfo?.profile?.name,
    surname: currentRoom.moreInfo?.profile?.surname,
    organization: currentRoom.moreInfo?.profile?.businessName ? currentRoom.moreInfo.profile.businessName : currentRoom.moreInfo?.premium?.businessName,
    country: currentRoom.moreInfo?.profile?.nation ? currentRoom.moreInfo?.profile?.nation : currentRoom.moreInfo?.premium?.businessNation
  } : currentBCard;

  function sendHandler(message) {
    dispatch(sendMessage({message}))
  }

  function archiveRoomHandler() {
    dispatch(archiveRoom({
      id: currentRoom.hasOwnProperty('roomId') ? currentRoom.roomId : currentRoom.userId,
      type: currentRoom.hasOwnProperty('roomId') ? 'room' : 'user'
    }));
  }

  function startChatHandler() {
    dispatch(startRoom({userId: currentRoom.userId}));
  }

  return (
    <Wrapper>
      {!isMobile ? <Spacer size={{value: 34, unit: "px"}}/> : null}
      {isLoadingRooms ? (<div>loading</div>) : (
        <MainContainer>
          <Container style={{flex: 1, display: 'flex'}}>
            {!isMobile || (isMobile && currentRoom) ? (
              <>
                {!isMobile ? (
                  <MacroRow layout={layout}>
                    <MacroCol mobile={isMobile} size="66" layout={layout}>
                      {currentRoom ?
                        <ChatContainer
                          attendeeId={attendee ? attendee.attendeeId : null}
                          isAgent={isAttendeeAgent}
                          isMobile={isMobile}
                          messages={messages}
                          isLoadingMessages={loadingMessages}
                          room={currentRoom}
                          isSending={messageIsSending}
                          onSend={sendHandler}
                          onStartRoom={startChatHandler}
                          onArchiveRoom={archiveRoomHandler}
                        />
                        : <Alert>Nessuna chat selezionata.</Alert>}
                    </MacroCol>
                    <MacroCol mobile={isMobile} size="34" layout={layout}>
                      {currentCompanyData && !isAttendeeAgent ?
                        <CompanyInfo
                          lang={lang}
                          data={currentCompanyData}
                          translations={translations}
                          showEvents={config.areas["events"].enable}
                        />
                        : null}
                      {currentBCard && isAttendeeAgent ? (
                        <UserInfo
                          imagePlaceholder={theme.placeholders.user}
                          data={currentBCard}
                        />
                        ) : null}
                    </MacroCol>
                  </MacroRow>
                ) : (
                  <ChatContainer
                    attendeeId={attendee ? attendee.attendeeId : null}
                    isAgent={isAttendeeAgent}
                    isMobile={isMobile}
                    messages={messages}
                    isLoadingMessages={loadingMessages}
                    room={currentRoom}
                    isSending={messageIsSending}
                    onSend={sendHandler}
                    onStartRoom={startChatHandler}
                    onArchiveRoom={archiveRoomHandler}
                  />
                )}
              </>
              ) : (
                <Sidebar mobile={true} />
            )}
          </Container>
        </MainContainer>
      )}
    </Wrapper>
  );

}
