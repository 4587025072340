import {VIR} from "./tenants/VIR/config";
import {MDM} from "./tenants/MDM/config";
import {AGR} from "./tenants/AGR/config";
import {FIC} from "./tenants/FIC/config";
import {SAM} from "./tenants/SAM/config";

export const DOMAINS = {
   "localhost": SAM,

   "recat-vir-dev.firebaseapp.com": VIR,
   "recat-vir-test.firebaseapp.com": VIR,
   "recat-vir-staging.firebaseapp.com": VIR,
   "recat-vir-dev.veronafiere.ozwol.tech": VIR,
   "recat-vir-test.veronafiere.ozwol.tech": VIR,
   "vinitalyplus.com": VIR,

   "recat-mdm-staging.firebaseapp.com": MDM,
   "recat-mdm-dev.veronafiere.ozwol.tech": MDM,
   "recat-mdm-test.veronafiere.ozwol.tech": MDM,
   "marmomacplus.com": MDM,

   "recat-agr-dev.veronafiere.ozwol.tech": AGR,
   "recat-agr-test.veronafiere.ozwol.tech": AGR,
   "catalogo.fieragricola.it": AGR,

   "recat-fic-dev.veronafiere.ozwol.tech": FIC,
   "recat-fic-test.veronafiere.ozwol.tech": FIC,
   "catalogo.fieracavalli.it": FIC,

   "recat-sam-dev.veronafiere.ozwol.tech": SAM,
   "recat-sam-test.veronafiere.ozwol.tech": SAM,
   "catalogo.samoter.it": SAM,
};
