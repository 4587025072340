import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslations } from "../../store/localization/selectors";
import styled from 'styled-components';

const Wrapper  = styled.a`
  color: #585858;
  font-weight: 400;
  font-size: 16px;
`;

const WebsiteLink = () => {
const translations = useSelector(getTranslations);
  return (
    <Wrapper href={translations["UI"]["_link-website"]}>
      {translations["UI"]["_label-website"]}
    </Wrapper>
  )
}

export default React.memo(WebsiteLink)
