import React from 'react';
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components';
import { withTheme } from './../../themes';
import Icon from "./../App/Icon";

const Wrapper  = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const HomeButton = ({
    size,
    color,
    background,
    theme
}) => {

    let { lang } = useParams();

    return (
        <Link to={`/${lang}`}>
            <Wrapper>
              <Icon
                name={theme.icons.set.homepage}
                size={size}
                color={color}
                background={background}
              />
            </Wrapper>
        </Link>
    )
}

export default withTheme(HomeButton)
