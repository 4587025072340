import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslations } from "../../store/localization/selectors";
import { useTheme } from '../../themes'
import Badge from "../App/Badge"

const UpdateNotification = () => {
  const translations = useSelector(getTranslations);
  const theme = useTheme()
  return (
    <Badge variant={theme.badgeVariant}>
      {translations["UI"]["_constantlyupdated"]}
    </Badge>
  )
}

export default React.memo(UpdateNotification)
