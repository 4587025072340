import React from 'react';
import styled from "styled-components";
import {chatReadableDate} from "../../../helpers";
import { withTheme } from "../../../themes";

const Wrapper = styled.div`
  padding-bottom: 25px;
  ${({self}) => self ? 'padding-left: 30%;' : 'max-width: 70%;'}
  ${({self}) => self ? 'text-align: right;' : ''}
`;

const Inner = styled.div`
  display: inline-block;
  border-radius: 10px;
  background: ${({self, color}) => self ? color : '#F2F4F4'};
  color: ${({self}) => self ? '#fff' : '##322A5D'};
  box-shadow: 0px 1px 1px #0000003E;
  padding: 15px 25px 15px 20px;
  
`;

const Time = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #000;
  text-transform: capitalize;
`;

const Original = styled.div`
  font-size: 12px;
  margin-top: 10px;
  color: #000;
`;

const Message = ({ theme, message = '', translation = null, creationTimestamp = null, createdAt = null, self = false, senderType = '' }) => {

  return (
    <Wrapper self={self}>
      <Time>{chatReadableDate(creationTimestamp || createdAt)}</Time>
      <Inner color={theme.colors.primary} self={self}>{(self || (!self && !translation)) ? message : translation}</Inner>
      {translation && (<Original>{self ? translation : message}</Original>)}
    </Wrapper>
  );
}

export default withTheme(Message);
