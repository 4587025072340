import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from "redux";
// import { useParams } from 'react-router-dom';
import {withTheme} from './../../../themes';
import {getLocale, getTranslations} from "./../../../store/localization/selectors";
import {getConfig, getLayout,} from './../../../store/settings/selectors';
import {getCompanyData, getFavorites, getIsPremiumUser, getRoles} from './../../../store/auth/selectors';
import {getActiveCompanies} from "../../../store/chat/selectors";
// import { DOMAINS } from "./../../../domains";
import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from './../../../settings'
import LabelledValue from "./../../../components/Common/LabelledValue"
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Chunk from './../../../components/App/Chunk';
import InfoRow from './../../../components/App/InfoRow';
import Gallery from './../../../components/App/Gallery';
import Image from './../../../components/App/Image';
import Button from './../../../components/App/Button';
import List from './../../../components/App/List';
import Icon from './../../../components/App/Icon';
import Modal from './../../../components/App/Modal/Modal';
import Logo from "./../../../components/App/Logo";
import ReadMore from "./../../../components/App/ReadMore";
import ReadMoreModal from "./../../../components/App/ReadMoreModal";
import Actionbar from "./../../../components/Common/Actionbar";
import FullModal from "./../../../components/Common/FullModal";

import Result from './../../../components/List/Sidebar/ResultsList/Result';
import CompanyResult from './../Companies/CompanyResult';
import ProductThumb from './../Products/ProductThumb';
// import EventThumb from './../Events/EventThumb';
import ContactForm from "./../../../components/Common/ContactForm";
import FindUs from "./../../../components/Common/FindUs";
import {getPermissions, getUser, hasPermissionTo} from "./../../../store/auth/selectors";
import {contactCompany, getCompanyActive} from "../../../store/chat/actions";

import {showPlansModal} from "../../../store/settings/actions";

import {PERMISSION_CHAT, PERMISSION_FAVOURITES, PERMISSION_NOTES} from "../../../store/auth/permissions";
import {setFavorite} from "../../../store/auth/actions";
import LetsFair from "../../../components/LetsFair/LetsFair"; //questo va nella popup

import {showFullModal, hideFullModal} from "./../../../store/settings/actions";

const Fixed = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #F5F5F5;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const Title = styled.div`
  font-weight: 900;
  font-size: 24px;
  color: #FFD300;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;

`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;

`;
const Col = styled.div`
  padding: 0px 7px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
const Center = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-around;
}
`;

const SlickSlide = styled.div`
  padding: 0 8px;
  box-sizing: border-box;
`;

const Text = styled.div`
  column-count: ${props => props.cols && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.cols : 1};

  & a:hover {
    font-weight: bold;
  }
`;

const ContactsModal = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 30px;
`;

const ContactsModalHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const SocialLink = styled.span`
  position: relative;
  top: -8px;
`;

const Position = styled.div`
  display: inline-block;
  background: #202128;
  color: #fff;
  text-align: center;
  padding: 8px 22px;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 10px;
`;

const HallImageCnt = styled.div`
  position: relative;
`;
const HallImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
const ListHighlight = styled.div`
  text-trasform: uppercase;
  color: #202128;
  font-size: 13px;
  font-weight: 700;
`;

const HallTitle = styled.div`
  color: #202128;
  font-size: 12px;
  font-weight: 700;
`;
const HallText = styled.div`
  color: #585858;
  font-size: 12px;
  margin-bottom: 10px;

`;

const MeetingModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 80%;
  margin: 0 auto;
  min-height: 100%;
  text-align: center;
`;
const MeetingModalText = styled.div`
  color: #585858;
  font-size: 12px;
  margin-bottom: 60px;

  & a{
    color: #202128;
  }
`;

const RelationsModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const RelationsModalTitle = styled.div`
  color: #202128;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;
const RelationsModalContent = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex-basis: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "calc(33.333333% - 12px)" : "100%"};
    margin-bottom: 15px;
  }
`;



const AttachmentsValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout != LAYOUT_XS && props.layout != LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #202128;
  align-items: center;
`;
const AttachmentsValueCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout != LAYOUT_XS && props.layout != LAYOUT_SM ? 15 : 10 }px 0px;
  margin-right: 10px;
  text-align: ${props => props.layout != LAYOUT_XS && props.layout != LAYOUT_SM ? "left" : "center" };

  &:nth-child(1){
    width: 75%;
  }
  &:nth-child(2){
    width: 25%;
  }
`;
const AttachmentName = styled.div`
  color: #202128;
  font-weight: bold;
  font-size: 13px;
`;

const mapStateToProps = (state) => ({
  translations: getTranslations(state),
  config: getConfig(state),
  layout: getLayout(state),
  user: getUser(state),
  lang: getLocale(state),
  permissions: getPermissions(state),
  isPremiumUser: getIsPremiumUser(state),
  favorites: getFavorites(state),
  activeCompanies: getActiveCompanies(state),
  companyData: getCompanyData(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

class CompanySheet extends Component {

  state = {
    showModal: null,
    chatEnabled: false
  }

  sectionReferences = null;

  constructor(props) {
    super(props);
    this.props = props;

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {

    if (!this.props.activeCompanies.hasOwnProperty(this.props.data.id)) {
      this.props.dispatch(getCompanyActive(this.props.data.id));
    }

  }

  componentDidUpdate(prevProps) {
  }

  toggleModal(modal) {
    this.setState({
      showModal: modal
    })
  }

  chatHandler = () => {

    if (!this.isCompanyActive()) return;

    const {permissions, dispatch} = this.props;
    const hasPermission = permissions.indexOf(PERMISSION_CHAT) !== -1;
    if (hasPermission) {
      this.props.dispatch(contactCompany({
        companyName: this.props.data.options.name,
        companyId: this.props.data.id
      }));
      this.props.history.push('/it/chat');
    } else {
      dispatch(showPlansModal())
    }


  }

  likeHandler = (favorites) => {

    this.props.dispatch(setFavorite({
      area: 'exhibitor',
      idElement: this.props.data.id,
      isFavorite: favorites && favorites.filter(item => item.itemType === "exhibitor" && item.itemId === this.props.data.id).length > 0
    }));

  }

  memoHandler = () => {
  }

  isCompanyActive = () => {
    const {activeCompanies, data} = this.props;
    return activeCompanies && data && activeCompanies.hasOwnProperty(data.id) && activeCompanies[data.id];
  }

  agendaHandler = () => {
    this.props.dispatch(hideFullModal());
    const {user, data, companyData} = this.props;

    if (!(user && user?.id)) return null;

    const args = {
      "nome": user.firstname,
      "cognome": user.lastname,
      "email": user.email,
      "idUtente": user.id,
      espositore: data.options.name,
      idEspositore: data.id,
      idCompany: data.scope.companyId,
      logoEspositore: data.options.logo ? data.options.logo.src : null,
      azienda: companyData?.name,
      ruolo: companyData?.activity,
      attivitaSpecifica: companyData?.activity2,
    }

    if (window.letzfair) {
      window.letzfair('meeting', args); //invoca questo e cazzi loro //se serve nel menu fai uguale di la nel brach letzfair
      // console.log('[LETZFAIR] meeting');
    } else {
      // console.log('[LETZFAIR] window.letzfair not found')
    }
    // console.log('[LETZFAIR] args:', args)

  }

  render() {
    const {props} = this;

    const theme = props.theme;
    const {data, translations, lang, config, layout, user, favorites, isPremiumUser} = props;

    const {showModal} = this.state;

    let chatEnabled = this.isCompanyActive();

    let modalText = '';

    if (showModal) {
      modalText = translations['sheet-company'][`_intro-contatto-diretto-${showModal.type}`].replace('%s', showModal.name);
    }

    return data ? (
      <>
        <Fixed>
          <Container>
            <Actionbar list={[
              {
                name: translations["UI"]["Memo"],
                icon: theme.icons.set.memo,
                onClick: this.memoHandler,
                show: false,
                isActive: false,
                isDisabled: false
              }, {
                name: favorites && favorites.filter(item => item.itemType === "exhibitor" && item.itemId === this.props.data.id).length ? translations["UI"]["Liked"] : translations["UI"]["Like"],
                icon: theme.icons.set.favorite,
                onClick: () => this.likeHandler(favorites),
                show: true,
                isActive: favorites && favorites.filter(item => item.itemType === "exhibitor" && item.itemId === this.props.data.id).length,
                isDisabled: false
              }, {
                name: translations["UI"]["Contact"],
                icon: theme.icons.set.contact,
                onClick: () => this.toggleModal({
                  type: 'company',
                  email: data.options.contacts.filter((item) => item.classification._id === "email").map((item) => item.value)[0],
                  name: data.options.name
                }),
                show: data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "email").length,
                isActive: false,
                isDisabled: false
              }, {
                name: translations["UI"]["Chat"],
                icon: theme.icons.set.chat,
                onClick: this.chatHandler,
                show: config.enable_chat,
                isActive: chatEnabled,
                isDisabled: !chatEnabled
              }, {
                name: translations["UI"]["Save"],
                icon: theme.icons.set.calendar,
                onClick: null,
                show: false,
                isActive: false,
                isDisabled: false
              }, {
                name: translations["UI"]["Meeting"],
                icon: theme.icons.set.calendar,
                onClick: () => this.props.dispatch(showFullModal(
                  <MeetingModal>
                    <div>
                      <MeetingModalText dangerouslySetInnerHTML={{__html: translations["sheet-company"]["_meeting-text"]}}></MeetingModalText>
                      <div>
                        <Button onClick={this.agendaHandler} variant={"default"} display={"inline"}>{translations["sheet-company"]["_meeting-button"]}</Button>
                      </div>
                    </div>
                  </MeetingModal>
                )),
                show: data.servicesData?.location && data.servicesData.location?.hallAndBooth && data.servicesData.location.hallAndBooth.filter((item) => item.exhibitionName === "Vinitaly Special Edition 2021").length > 0,
                isActive: false,
                isDisabled: !(user && user?.id && isPremiumUser)
              }
            ]}/>
          </Container>
        </Fixed>
        <Wrapper>
          <MainContainer>
            <Container>

              <Title>
                <Icon color={"#FFD300"} name={theme.icons.set.homepage} size={{value: 25, unit: "px"}}/>&nbsp;&nbsp;
                {data.options.name}
              </Title>
              <Spacer size={{value: 12, unit: "px"}}/>

              <MacroRow layout={layout}>
                <MacroCol size="66" layout={layout}>
                  {data.options.logo || data.options.headerImage ?
                    <>
                      <Row layout={layout}>
                        {data.options.logo ?
                          <Col size={data.options.headerImage ? 50 : 100} layout={layout}>
                            <Chunk open={true} collapsible={false} fullHeight={true}>
                              <Image fit={"contain"} height={{"value": 100, "unit": "px"}} src={data.options.logo.src} alt="logo"/>
                            </Chunk>
                          </Col>
                        : null}
                        {layout != LAYOUT_XS && layout != LAYOUT_SM ?
                          null
                        :
                          <Spacer size={{value: 12, unit: "px"}}/>
                        }
                        {data.options.headerImage ?
                          <Col size={data.options.logo ? 50 : 100} layout={layout}>
                            <Chunk open={true} collapsible={false} fullHeight={true} noPadding={true}>
                              <Image
                                width={{value: 100, unit: "%"}}
                                height={layout != LAYOUT_XS && layout != LAYOUT_SM ?
                                  (data.logo ? {"value": 100, "unit": "%"} : {"value": 150, "unit": "px"})
                                :
                                  {"value": 150, "unit": "px"}
                                }
                                src={data.options.headerImage.src}
                                alt="cover"
                              />
                            </Chunk>
                          </Col>
                        : null}
                      </Row>
                      <Spacer size={{value: 14, unit: "px"}}/>
                    </>
                  : null}
                  <Chunk title={translations["sheet-company"]["Contacts"]}>

                    {/*data.country || data.region || data.province ?
                      <InfoRow
                        label={translations["sheet-company"]["Geographical area"]}
                        value={<>
                          {data.country} <br/>
                          {data.region}<br/>
                          {data.province}
                        </>}
                      />
                    : null */}
                    {data.options.locations && data.options.locations.length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Address"]}
                        value={data.options.locations.map((val, i) =>
                          <>
                            {val.classification && val.classification[lang] ? <><b>{val.classification[lang]}</b><br/></> : null}
                            {val.address}<br/>
                            {val.city} {val.zip} {val.provinceId ? val.provinceId[lang] : null}<br/>
                            {val.countryId ? val.countryId[lang] : null}<br/>
                            {i < data.options.locations.length-1 ?<br/> : null}
                          </>
                        )}
                      />
                    : null}
                    {data.options.languages && data.options.languages.length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Languages spoken"]}
                        value={data.options.languages.map(val =>
                          <span key={`language-${val[lang]}`}>{val[lang]}<br/></span>
                        )}
                      />
                    : null}
                    {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "web").length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Website"]}
                        value={data.options.contacts.filter((item) => item.classification._id === "web").map(val =>
                          <a href={val.value} key={`website-${val.value}`}>{val.value}<br/></a>
                        )}
                      />
                      : null}
                    {data.options.contacts && data.options.contacts.filter((item) => item.subtype._id === "phone;phone-tel" || item.subtype._id === "phone;phone-cell").length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Phone"]}
                        value={data.options.contacts.filter((item) => item.subtype._id === "phone;phone-tel" || item.subtype._id === "phone;phone-cell").map(val =>
                          <span key={`phones-${val.value}`}>{val.value}<br/></span>
                        )}
                      />
                    : null}
                    {data.options.contacts && data.options.contacts.filter((item) => item.subtype._id === "phone;phone-fax").length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Fax"]}
                        value={data.options.contacts.filter((item) => item.subtype._id === "phone;phone-fax").map(val =>
                          <span key={`faxes-${val.value}`}>{val.value}<br/></span>
                        )}
                      />
                    : null}
                    {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "social").length > 0 ?
                      <InfoRow label={translations["sheet-company"]["Social"]}>
                        {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "social").map((item, i) =>
                          <a key={i} href={item.value} target="_blank"><Icon color={"#606060"}
                                                                             name={(item.subtype._id.replace("social;", "")) + "-1"}
                                                                             size={{value: 20, unit: "px"}}/>
                            <SocialLink>{item.subtype[lang]}</SocialLink><br/></a>
                        )}
                      </InfoRow>
                    : ""}
                  </Chunk>

                  <Spacer size={{value: 14, unit: "px"}}/>
                  {data.options.people && data.options.people.length > 0 ?
                    <>
                      <Chunk title={translations["sheet-company"]["About us"]} open={false}>

                        <Row layout={layout}>
                          {["president", "ceo"].map((role, i) =>
                            data.options.people.filter(item => item.classification._id === role).length > 0 ?
                              <Col size="50" key={i}>
                                <LabelledValue
                                  label={data.options.people.filter(item => item.classification._id === role)[0].classification[lang]}
                                  border={false}>
                                  {data.options.people.filter(item => item.classification._id === role)[0].firstName + " " + data.options.people.filter(item => item.classification._id === role)[0].lastName}<br/><br/>
                                  {data.options.people.filter(item => item.classification._id === role)[0].contacts.filter((item) => item.classification._id === "email").length > 0 ?
                                    <Button
                                      onClick={() => this.toggleModal({
                                        type: 'companycontact',
                                        email: data.options.people.filter(item => item.classification._id === role)[0].contacts.filter((item) => item.classification._id === "email")[0].value,
                                        name: data.options.people.filter(item => item.classification._id === role)[0].firstName + " " + data.options.people.filter(item => item.classification._id === role)[0].lastName
                                      })}
                                      size={"tiny"}
                                      variant={"default-o"}
                                      display={"inline-block"}
                                    >
                                      {translations["sheet-company"]["Contact directly"]}
                                    </Button>
                                  : ""}
                                </LabelledValue>
                              </Col>
                            : null
                          )}
                          {data.options.people.filter(item => item.classification._id !== "president" && item.classification._id !== "ceo").length > 0 ?
                            data.options.people.filter(item => item.classification._id !== "president" && item.classification._id !== "ceo").map((person, i) =>
                              <Col size="25" key={i}>
                                <LabelledValue label={person.classification[lang]} border={false}>
                                  {person.firstName + " " + person.lastName}<br/><br/>
                                  {person.contacts.filter((item) => item.classification._id === "email").length > 0 ?
                                    <Button
                                      onClick={() => this.toggleModal({
                                        type: 'companycontact',
                                        email: person.contacts.filter((item) => item.classification._id === "email")[0].value,
                                        name: person.firstName + " " + person.lastName
                                      })}
                                      size={"tiny"}
                                      variant={"default-o"}
                                      display={"inline-block"}
                                    >
                                      {translations["sheet-company"]["Contact directly"]}
                                    </Button>
                                  : ""}
                                </LabelledValue>
                              </Col>
                            )
                          : null}
                        </Row>
                      </Chunk>
                      <Spacer size={{value: 14, unit: "px"}}/>
                    </>
                  : null}

                  {
                    (data.options.brandIdentities && data.options.brandIdentities.length > 0) ||
                    (data.options.ownerName) ||
                    (data.options.foundationYear) ||
                    (data.options.typology) ||
                    (data.options.wineLabels) ||
                    (data.options.brands && data.options.brands.length > 0) ||
                    (data.options.openForTastings) ||
                    (data.options.annualRevenue) ||
                    (data.options.employeesNumber) ||
                    (data.options.otherInfos && data.options.otherInfos.length > 0) ?
                      <>
                        <Chunk title={translations["sheet-company"]["Company info"]}>
                          {data.options.brandIdentities && data.options.brandIdentities.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Identity"]}
                              value={data.options.brandIdentities.map((item) => item[lang]).join(", ")}
                            />
                          : ""}
                          {data.options.ownerName ?
                            <InfoRow
                              label={translations["sheet-company"]["Owner"]}
                              value={data.options.ownerName}
                            />
                          : ""}
                          {data.options.foundationYear ?
                            <InfoRow
                              label={translations["sheet-company"]["Foundation year"]}
                              value={data.options.foundationYear}
                            />
                          : ""}
                          {data.options.typology ?
                            <InfoRow
                              label={translations["sheet-company"]["Company typology"]}
                              value={data.options.typology[lang]}
                            />
                          : ""}
                          {/*
                          {data.options.wineLabels ?
                            <InfoRow
                              label={translations["sheet-company"]["Number of labels produced"]}
                              value={data.options.wineLabels}
                            />
                          : ""}*/}
                          {data.options.brands && data.options.brands.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Brands"]}
                              value={data.options.brands.join(", ")}
                            />
                          : ""}
                          {/*
                          {data.hospitality > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Has hospitality"]}
                              value={translations["UI"]["Yes"]}
                            />
                          : ""}
                          {data.options.openForTastings ?
                            <InfoRow
                              label={translations["sheet-company"]["Open for tastings"]}
                              value={data.options.openForTastings[lang]}
                            />
                            : ""}*/}
                          {data.options.annualRevenue ?
                            <InfoRow
                              label={translations["sheet-company"]["Revenue"]}
                              value={data.options.annualRevenue[lang]}
                            />
                          : ""}
                          {data.options.employeesNumber ?
                            <InfoRow
                              label={translations["sheet-company"]["Employees number"]}
                              value={data.options.employeesNumber[lang]}
                            />
                          : ""}
                          {data.options.otherInfos && data.options.otherInfos.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Other information"]}
                              value={data.options.otherInfos.map(val => val[lang]).join(", ")}
                            />
                          : ""}
                        </Chunk>
                        <Spacer size={{value: 14, unit: "px"}}/>
                      </>
                    : null}

                  {
                    (data.options.categories && data.options.categories.length > 0) ||
                    (data.options.productionAreas && data.options.productionAreas.length > 0) ||
                    (data.options.hectares) ||
                    (data.options.bottlesPerYear) ||
                    (data.options.ownGrapesPercentage) ||
                    (data.options.sustainability && data.options.sustainability.length > 0) ||
                    (data.options.packaging && data.options.packaging.length > 0) ||
                    (data.skills && data.skills.length > 0) ?
                      <>
                        <Chunk title={translations["sheet-company"]["Production"]}>
                          {data.options.categories && data.options.categories.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Trade Sectors"]}
                              value={null}
                            >
                              <List>
                                <ul>
                                  {data.options.categories.map((lvl0, index0) =>
                                    <li key={index0}>
                                      <ListHighlight>{lvl0.value[lang]}</ListHighlight>
                                      {lvl0.children ?
                                        <ul>
                                          {lvl0.children.map((lvl1, index1) =>
                                            <li key={index1}>
                                              {lvl1.value[lang]}
                                              {lvl1.children ?
                                                <ul>
                                                  {lvl1.children.map((lvl2, index2) =>
                                                    <li key={index2}>
                                                      {lvl2.value[lang]}
                                                      {lvl2.children ?
                                                        <ul>
                                                          {lvl2.children.map((lvl3, index3) =>
                                                            <li key={index3}>
                                                              {lvl3.value[lang]}
                                                              {lvl3.children ?
                                                                <ul>
                                                                  {lvl3.children.map((lvl4, index4) =>
                                                                    <li key={index4}>{lvl4.value[lang]}</li>
                                                                  )}
                                                                </ul>
                                                                : ""}
                                                            </li>
                                                          )}
                                                        </ul>
                                                        : ""}
                                                    </li>
                                                  )}
                                                </ul>
                                                : ""}
                                            </li>
                                          )}
                                        </ul>
                                        : ""}
                                    </li>
                                  )}
                                </ul>
                              </List>
                              {/*<Row>
                                <Col>The quick, brown fox jumps over a lazy dog DJs flock by when MTV ax quiz prog Junk MTV quiz graced by fox whelps Bawds Waltzbad nymph for quick jigs ve Fox nymphs grab quick-jived waltz Brick quiz whangs jumpy veldt fox Bright vixens fowl quack Quick zephyrs vex bold Jim</Col>
                                <Col>The quick, brown fox jumps over a lazy dog DJs flock by when MTV ax quiz prog Junk MTV quiz graced by fox whelps Bawds Waltzbad nymph for quick jigs ve Fox nymphs grab quick-jived waltz Brick quiz whangs jumpy veldt fox Bright vixens fowl quack Quick zephyrs vex bold Jim</Col>
                                <Col>The quick, brown fox jumps over a lazy dog DJs flock by when MTV ax quiz prog Junk MTV quiz graced by fox whelps Bawds Waltzbad nymph for quick jigs ve Fox nymphs grab quick-jived waltz Brick quiz whangs jumpy veldt fox Bright vixens fowl quack Quick zephyrs vex bold Jim</Col>
                              </Row>*/}
                            </InfoRow>
                          : ""}
                          {data.skills && data.skills.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Specializations"]}
                              value={data.skills.join(", ")}
                            />
                          : ""}
                          {data.options.productionAreas && data.options.productionAreas.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Production area"]}
                              value={null}
                            >
                              {/*<Text cols="3" layout={layout}>
                                {data.options.productionAreas.map(val =>
                                  <span key={`production-area-${val.countryId[lang]+"-"+val.regionId[lang]}`}>{val.countryId[lang]}{val.regionId[lang] ? ": " + val.regionId[lang].join(", ") : ""}
                                    <br/></span>
                                )}
                              </Text>*/}
                              <Text cols="3" layout={layout}>
                                {data.options.productionAreas.map(valCountry => valCountry.countryId[lang]).filter((item, pos, self) => self.indexOf(item) == pos).map(valCountry =>
                                  <span key={`production-area-${valCountry}`}>{valCountry}{data.options.productionAreas.filter(valRegion => valRegion.countryId[lang] === valCountry && valRegion.regionId !== null).length > 0 ? ": " + data.options.productionAreas.filter(valRegion => valRegion.countryId[lang] === valCountry && valRegion.regionId !== null).map(valRegion => valRegion.regionId[lang]).join(", ") : null}
                                    <br/></span>
                                )}
                              </Text>
                            </InfoRow>
                          : ""}
                          {/*
                          {data.options.hectares ?
                            <InfoRow
                              label={translations["sheet-company"]["Cultivated hectares"]}
                              value={data.options.hectares}
                            />
                          : ""}
                          {data.options.bottlesPerYear ?
                            <InfoRow
                              label={translations["sheet-company"]["Total annual production"]}
                              value={data.options.bottlesPerYear[lang]}
                            />
                            : ""}*/}
                            {/*
                          {data.options.ownGrapesPercentage ?
                            <InfoRow
                              label={translations["sheet-company"]["Grapes"]}
                              value={translations["sheet-company"]["_own-grapes-percent"].replace(/%s/g, data.options.ownGrapesPercentage) + ", " + translations["sheet-company"]["_purchased-grapes-percent"].replace(/%s/g, 100 - data.options.ownGrapesPercentage)}
                            />
                          : ""}*/}
                          {data.options.sustainability && data.options.sustainability.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Sustainability"]}
                              value={data.options.sustainability.map(val => val[lang]).join(", ")}
                            />
                          : ""}

                            {/*
                          {data.options.packaging && data.options.packaging.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Available packaging"]}
                              value={data.options.packaging.map(val => val[lang]).join(", ")}
                            />
                          : ""}*/}
                        </Chunk>
                        <Spacer size={{value: 14, unit: "px"}}/>
                      </>
                    : null}

                  {config.areas["products"].enable && data.products && data.products.length > 0 ?

                    data.products.map((item, index) => (
                      <React.Fragment key={index}>
                        <Chunk collapsible={false}>
                          <ProductThumb
                            key={index}
                            item={item}
                          />
                        </Chunk>
                        <Spacer size={{value: 14, unit: "px"}}/>
                      </React.Fragment>
                    ))
                  : null}

                  {
                    (data.options.mainOperation) ||
                    (data.options.subsidiaryCompanyLocation && data.options.subsidiaryCompanyLocation.length > 0) ||
                    (data.options.dealers) ||
                    (data.options.certificates) ||
                    (data.options.distributionChannelsItaly && data.options.distributionChannelsItaly.length > 0) ||
                    (data.options.exportPercentage) ||
                    (data.options.exportTo && data.options.exportTo.length > 0) ||
                    (data.options.desiredExportTo && data.options.desiredExportTo.length > 0) ||


                    (data.channelsItaly && data.channelsItaly.length > 0) ?
                      <>
                        <Chunk title={translations["sheet-company"]["Distribution"]}>
                          {data.options.mainOperation ?
                            <InfoRow
                              label={translations["sheet-company"]["Prevailing operations"]}
                              value={data.options.mainOperation[lang]}
                            />
                            : ""}
                            {data.options.dealers !== null ?
                              <InfoRow
                                label={translations["sheet-company"]["Dealers"]}
                                value={data.options.dealers ? translations["UI"]["Yes"] : translations["UI"]["No"]}
                              />
                            : ""}
                            {data.options.certificates ?
                              <InfoRow
                                label={translations["sheet-company"]["Certifications"]}
                                value={data.options.certificates}
                              />
                              : ""}
                          {data.options.distributionChannelsItaly && data.options.distributionChannelsItaly.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Distribution channels in Italy"]}
                              value={data.options.distributionChannelsItaly.map(val => val[lang]).join(", ")}
                            />
                          : ""}
                          {data.options.exportPercentage ?
                            <InfoRow
                              label={translations["sheet-company"]["Export percent"]}
                              value={data.options.exportPercentage + "%"}
                            />
                            : ""}
                            {data.options.subsidiaryCompanyLocation && data.options.subsidiaryCompanyLocation.length > 0 ?
                              <InfoRow
                                label={translations["sheet-company"]["Subsidiary company"]}
                                value={data.options.subsidiaryCompanyLocation.map(val => val[lang]).join(", ")}
                              />
                            : ""}
                          {data.options.exportTo && data.options.exportTo.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Distribution channels"]}
                              value={null}
                            >
                              <Text cols="3" layout={layout}>
                                {data.options.distributionChannelIds.map((val, i) =>
                                  <span key={i}>{val[lang]}<br/></span>
                                )}
                              </Text>
                            </InfoRow>
                            : ""}
                          {data.options.exportTo && data.options.exportTo.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Countries to which we export"]}
                              value={null}
                            >
                              <Text cols="3" layout={layout}>
                                {data.options.exportTo.map((val, i) =>
                                  <span
                                    key={`export-to-${i}`}>{val[lang]}<br/></span>
                                )}
                              </Text>
                            </InfoRow>
                          : ""}
                          {data.options.desiredExportTo && data.options.desiredExportTo.length > 0 ?
                            <InfoRow
                              label={translations["sheet-company"]["Countries where we would like to export"]}
                              value={null}
                            >
                              {/*<Text cols="3" layout={layout}>
                                {data.options.desiredExportTo.map(val =>
                                  <span
                                    key={`desired-export-to-${val.countryId[lang]}`}>{val.countryId[lang]}{val.distributionChannelIds.length > 0 ? ": " + val.distributionChannelIds.map(val => val[lang]).join(", ") : ""}
                                    <br/></span>
                                )}
                              </Text>*/}
                              <Text cols="3" layout={layout}>
                                {data.options.desiredExportTo.map((val, i) =>
                                  <span
                                    key={`export-to-${i}`}>{val[lang]}<br/></span>
                                )}
                              </Text>
                            </InfoRow>
                          : ""}
                        </Chunk>
                        <Spacer size={{value: 14, unit: "px"}}/>
                      </>
                    : null}


                  {(data.options.gallery && data.options.gallery.length > 0) || (data.options.video && data.options.video.length > 0) ?
                    <>
                      <Chunk title={translations["sheet-company"]["Gallery"]} open={true}>
                        <Gallery
                          images={data.options.gallery && data.options.gallery.length > 0 ? data.options.gallery : []}
                          videos={data.options.video && data.options.video.length > 0 ? data.options.video : []}
                          lang={lang}
                        />
                      </Chunk>
                      <Spacer size={{value: 14, unit: "px"}}/>
                    </>
                  : null}

                    {data.options && ((lang === "it" && data.options.descriptionSheetItalian) || (lang === "en" && data.options.descriptionSheetEnglish)) ?
                      <>
                        <Chunk title={translations["sheet-company"]["Attachments"]}>
                          {lang === "it" && data.options.descriptionSheetItalian ?
                            <AttachmentsValue layout={layout}>
                              <AttachmentsValueCell layout={layout}>
                                <AttachmentName>{data.options.descriptionSheetItalian.options.lTitle[lang]}</AttachmentName>
                              </AttachmentsValueCell>
                              <AttachmentsValueCell layout={layout}>
                                <a href={data.options.descriptionSheetItalian.src} target="_blank">
                                  <Button display="inline-block" variant={"default-o"}>{translations["UI"]["Download"]}</Button>
                                </a>
                              </AttachmentsValueCell>
                            </AttachmentsValue>
                          : null}
                          {lang === "en" && data.options.descriptionSheetEnglish ?
                            <AttachmentsValue layout={layout}>
                              <AttachmentsValueCell layout={layout}>
                                <AttachmentName>{data.options.descriptionSheetEnglish.options.lTitle[lang]}</AttachmentName>
                              </AttachmentsValueCell>
                              <AttachmentsValueCell layout={layout}>
                                <a href={data.options.descriptionSheetEnglish.src} target="_blank">
                                  <Button display="inline-block" variant={"default-o"}>{translations["UI"]["Download"]}</Button>
                                </a>
                              </AttachmentsValueCell>
                            </AttachmentsValue>
                          : null}
                        </Chunk>
                        <Spacer size={{value: 25, unit: "px"}} />
                      </>
                    : null }


                </MacroCol>
                <MacroCol size="34" layout={layout}>
                {(data.servicesData && data.servicesData.location && data.servicesData.location.hallAndBooth && data.servicesData.location.hallAndBooth.length > 0) && config.enable_halls ?
                  <>
                    <FindUs>
                      <HallImageCnt>
                        <Image src={theme.halls["void"]} alt=""/>
                        {data.servicesData.location.hallAndBooth.map((item, index) =>
                          <HallImage key={"hallimage-"+index}>
                            <Image src={theme.halls[item.hall]} alt=""/>
                          </HallImage>
                        )}
                      </HallImageCnt>
                      <ReadMore
                        visibleContent={data.servicesData.location.hallAndBooth.slice(0, 3).map((item, index) =>
                          <Position key={`hall-${index}`}>{item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")}</Position>
                        )}
                        hiddenContent={data.servicesData.location.hallAndBooth.slice(3).length ? data.servicesData.location.hallAndBooth.slice(3).map((item, index) =>
                          <Position key={`hall-${index}`}>{item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")}</Position>
                        ): null}
                        buttonMore={<Button size={"tiny"} variant={"default-o"}
                                            display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                        buttonLess={<Button size={"tiny"} variant={"default-o"}
                                            display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                      />
                    </FindUs>

                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}


                {data.options.brands && data.options.brands.length > 0 ?
                  <>
                    <Chunk title={translations["sheet-company"]["Brands"]} collapsible={false}>
                      {data.options.brands.join(", ")}
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : ""}


                {data.relations && data.relations.coexhibs && data.relations.coexhibs.length > 0 ?
                  <>
                    <Chunk title={translations["sheet-company"]["Coexhibitors"]} collapsible={false}>
                      <ReadMoreModal
                        visibleContent={data.relations.coexhibs.slice(0, 2).map(val =>
                          val.id ?
                            <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                              <CompanyResult item={val}/>
                            </Link>
                            :
                            <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                        )}
                        hiddenContent={
                          data.relations.coexhibs.length > 2 ?
                            <RelationsModal>
                              <RelationsModalTitle>
                                {translations["sheet-company"]["Coexhibitors"]}<br/>
                                {data.options.name}
                              </RelationsModalTitle>
                              <RelationsModalContent layout={layout}>
                              {data.relations.coexhibs.map(val =>
                                <div onClick={() => this.props.dispatch(hideFullModal())}>
                                  {val.id ?
                                    <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                                      <CompanyResult item={val}/>
                                    </Link>
                                    :
                                    <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                                  }
                                </div>
                              )}
                              </RelationsModalContent>
                            </RelationsModal>
                          : null
                        }
                        buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}>
                      </ReadMoreModal>
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                {data.relations && data.relations.represents && data.relations.represents.length > 0 ?
                  <>
                    <Chunk title={translations["sheet-company"]["Represented"]} collapsible={false}>
                      <ReadMoreModal
                        visibleContent={data.relations.represents.slice(0, 2).map(val =>
                          val.id ?
                            <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                              <CompanyResult item={val}/>
                            </Link>
                            :
                            <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                        )}
                        hiddenContent={
                          data.relations.represents.length > 2 ?
                            <RelationsModal>
                              <RelationsModalTitle>
                                {translations["sheet-company"]["Represented"]}<br/>
                                {data.options.name}
                              </RelationsModalTitle>
                              <RelationsModalContent layout={layout}>
                              {data.relations.represents.map(val =>
                                <div onClick={() => this.props.dispatch(hideFullModal())}>
                                  {val.id ?
                                    <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                                      <CompanyResult item={val}/>
                                    </Link>
                                    :
                                    <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                                  }
                                </div>
                              )}
                              </RelationsModalContent>
                            </RelationsModal>
                          : null
                        }
                        buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}>
                      </ReadMoreModal>
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                {data.relations && data.relations.coexhibsChild && data.relations.coexhibsChild.length > 0 ?
                  <>
                    <Chunk title={translations["sheet-company"]["Coexhibitor of"]} collapsible={false}>
                      <ReadMoreModal
                        visibleContent={data.relations.coexhibsChild.slice(0, 2).map(val =>
                          val.id ?
                          <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                            <CompanyResult item={val}/>
                          </Link>
                          :
                          <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                        )}
                        hiddenContent={
                          data.relations.coexhibsChild.length> 2 ?
                            <RelationsModal>
                              <RelationsModalTitle>
                                {translations["sheet-company"]["Coexhibitor of"]}<br/>
                                {data.options.name}
                              </RelationsModalTitle>
                              <RelationsModalContent layout={layout}>
                              {data.relations.coexhibsChild.map(val =>
                                <div onClick={() => this.props.dispatch(hideFullModal())}>
                                  {val.id ?
                                    <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                                      <CompanyResult item={val}/>
                                    </Link>
                                    :
                                    <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                                  }
                                </div>
                              )}
                              </RelationsModalContent>
                            </RelationsModal>
                          : null
                        }
                        buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}>
                      </ReadMoreModal>
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                {data.relations && data.relations.representsChild && data.relations.representsChild.length > 0 ?
                  <>

                    <Chunk title={translations["sheet-company"]["Representing"]} collapsible={false}>
                      <ReadMoreModal
                        visibleContent={data.relations.representsChild.slice(0, 2).map(val =>
                          val.id ?
                            <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                              <CompanyResult item={val}/>
                            </Link>
                            :
                            <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                        )}
                        hiddenContent={
                          data.relations.representsChild.length > 2 ?
                            <RelationsModal>
                              <RelationsModalTitle>
                                {translations["sheet-company"]["Representing"]}<br/>
                                {data.options.name}
                              </RelationsModalTitle>
                              <RelationsModalContent layout={layout}>
                              {data.relations.representsChild.map(val =>
                                <div onClick={() => this.props.dispatch(hideFullModal())}>
                                  {val.id ?
                                    <Link to={"/" + lang + "/companies/" + val.id} key={val.id}>
                                      <CompanyResult item={val}/>
                                    </Link>
                                    :
                                    <span key={`coexhibs-${val.name}`}>{val.name}<br/></span>
                                  }
                                </div>
                              )}
                              </RelationsModalContent>
                            </RelationsModal>
                          : null
                        }
                        buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}>
                      </ReadMoreModal>
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                  {config.areas["events"].enable && data.events.length > 0 ?
                    <>
                      <Chunk title={translations["sheet-company"]["Events"]} collapsible={false}>
                        <ReadMore
                          visibleContent={data.events.slice(0, 2).map((item, index) => (
                            <Result
                              key={index}
                              slug={item.slug}
                              area="events"
                              item={item}
                              url={"/" + lang + "/events/" + item.type + "/" + item.id}
                            />
                          ))}
                          hiddenContent={data.events.slice(2).length ? data.events.slice(2).map((item, index) => (
                            <Result
                              key={index}
                              slug={item.slug}
                              area="events"
                              item={item}
                              url={"/" + lang + "/events/" + item.type + "/" + item.id}
                            />
                          )) : null}
                          buttonMore={<Button size={"tiny"} variant={"default-o"}
                                              display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                          buttonLess={<Button size={"tiny"} variant={"default-o"}
                                              display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                        />

                      </Chunk>

                      <Spacer size={{value: 14, unit: "px"}}/>
                    </>
                  : null}
                </MacroCol>
              </MacroRow>


            </Container>
          </MainContainer>
        </Wrapper>
        {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "email").length && (
          <Modal show={showModal} onClose={e => this.toggleModal(false)}>
            <ContactsModal>
              <ContactsModalHeader>
                {/*data.options.logo ?
                  <Image src={data.options.logo.src} width={{"value": 200, "unit": "px"}}/>
                : null*/}
                <p>{modalText}</p>
              </ContactsModalHeader>
              <ContactForm
                lang={lang}
                config={config}
                translations={translations}
                company={data.options.name}
                reset={!showModal}
                toEmail={showModal ? data.options.contacts.filter((item) => item.classification._id === "email").map((item) => item.value) : null}
                toName={showModal ? data.options.name : null} type={showModal ? showModal.type : null}
              />
            </ContactsModal>
          </Modal>
        )}
      </>
    ) : <></>;

  }

}

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanySheet);
