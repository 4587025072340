import React, { useState } from 'react';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { useTheme } from './../../themes';
import { getLayout } from './../../store/settings/selectors';

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

import Icon from "./../App/Icon";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Cutter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% * ${props => props.count});
`;
const Mover = styled.div`
  position: relative;
  left: calc(calc(100% / ${props => props.count * props.size}) * -${props => props.offset});
  width: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.15s ease-in-out;
`;
const Item = styled.div`
  width: calc(100% / ${props => props.count * props.size});
  padding: ${props => props.padding.value+props.padding.unit};
`;
const Dots = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Dot = styled.div`
  margin: 0px ${props => (props.spacing.value/2)+props.spacing.unit};
  width: ${props => props.size.value+props.size.unit};
  height: ${props => props.size.value+props.size.unit};
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  background-color: ${props => props.isActive ? props.colorActive : props.colorUnactive};
  cursor: pointer;
`;
const ArrowLeft = styled.div`
  position: absolute;
  top: 25%;
  left: ${props => -props.offset.value+props.offset.unit};
  border: 1px solid ${props => props.border};
  background: ${props => props.background};
  border-top-left-radius: 0px;
  border-top-right-radius: ${props => props.radius.value+props.radius.unit};
  border-bottom-right-radius: ${props => props.radius.value+props.radius.unit};
  border-bottom-left-radius: 0px;
  width: ${props => props.width.value+props.width.unit};
  height: ${props => props.height.value+props.height.unit};
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;
const ArrowRight = styled.div`
  position: absolute;
  top: 25%;
  right: ${props => -props.offset.value+props.offset.unit};
  border: 1px solid ${props => props.border};
  background: ${props => props.background};
  border-top-left-radius: ${props => props.radius.value+props.radius.unit};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: ${props => props.radius.value+props.radius.unit};
  width: ${props => props.width.value+props.width.unit};
  height: ${props => props.height.value+props.height.unit};
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;


const Slider = ({
  items,
  size,
  variant = "default",
  showDots = true,
  showArrows = true,
  timing = null,
  padding = {value: 10, unit: "px"}
}) => {

  const theme = useTheme();
  const layout = useSelector(getLayout);
  const [offset, setOffset] = useState(0);

  if(timing){
    setTimeout(function(){
      setOffset(offset < items.length-size ? offset+1 : 0)
    }, timing);
  }

  return (
    <Wrapper>
      <Cutter>
        <Container count={items.length}>
          <Mover offset={offset} count={items.length} size={size}>
            {items.map((item, index) =>
              <Item count={items.length} size={size} key={index} padding={padding}>
                {item}
              </Item>
            )}
          </Mover>
        </Container>
      </Cutter>
      {showArrows ?
        <>
          <ArrowLeft
            onClick={() => setOffset(offset > 0 ? offset-1 : 0)}
            background={theme.slider.variant[variant].arrows.background}
            border={theme.slider.variant[variant].arrows.border}
            radius={theme.slider.arrows.borderRadius}
            offset={theme.slider.arrows.offset}
            width={theme.slider.arrows.width}
            height={theme.slider.arrows.height}
          >
            <Icon color={theme.slider.variant[variant].arrows.color} name={theme.icons.set.menuitemPrev} size={theme.slider.arrows.size} />
          </ArrowLeft>
          <ArrowRight
            onClick={() => setOffset(offset < items.length-size ? offset+1 : items.length-size)}
            background={theme.slider.variant[variant].arrows.background}
            border={theme.slider.variant[variant].arrows.border}
            radius={theme.slider.arrows.borderRadius}
            offset={theme.slider.arrows.offset}
            width={theme.slider.arrows.width}
            height={theme.slider.arrows.height}
          >
            <Icon color={theme.slider.variant[variant].arrows.color} name={theme.icons.set.menuitemNext} size={theme.slider.arrows.size} />
          </ArrowRight>
        </>
      : null }
      {showDots ?
        <Dots>
          {items.map((item, index) =>(
            index < items.length - size + 1 ?
              <Dot
                key={index}
                isActive={offset === index}
                onClick={() => setOffset(index)}
                colorActive={theme.slider.variant[variant].dots.colorActive}
                colorUnactive={theme.slider.variant[variant].dots.colorUnactive}
                borderRadius={theme.slider.dots.borderRadius}
                size={theme.slider.dots.size}
                spacing={theme.slider.dots.spacing}
              />
            :
              null
          ))}
        </Dots>
      : null }
    </Wrapper>
  )
}

export default React.memo(Slider)
