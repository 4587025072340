import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import en_UK from './translations/en.json'
import it_IT from './translations/it.json'

export const translations = {
  "en": en_UK,
  "it": it_IT
} 


export const initializeLocales = (lang = "en") => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: en_UK
        },
        it: {
          translation: it_IT
        }
      },

      detection: {
        order: ['localStorage', 'navigator']
      },

      fallbackLng: "en",

      interpolation: {
        escapeValue: false
      }
    })

}

export default i18n
