import React, { useState } from 'react'
import {useSelector} from "react-redux";
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser';
import Icon from "../../../components/App/Icon";
import {chatReadableDate} from "../../../helpers";
import {getCompanySubtitle, getUnreadMessagesCount} from "../../../store/chat/selectors";
import {withTheme} from "../../../themes";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0px 0px;
  padding: 15px 15px;
  background-color: ${props => props.isActive ? "#ffffff" : "#EDEDED"};
  border-radius: 10px;
  /* border: 1px solid #EDEDED;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  cursor: ${props => props.active ? 'default' : 'pointer'}; */

  /* &:hover{
    background-color: #ffffff;
  } */
  ${props => !props.isActive ? `
   &:hover{
     background-color: #ffffff;
   }
 ` : ""};
`;
const Subwrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`;
const Left = styled.div`
  margin-right: 10px;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  display: flex;
`;

const UpperText = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #585858;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const TitleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({color}) => color};
  padding: 6px 0px;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const LowerText = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
  color: #585858;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;

const Count = styled.div`
  border-radius: 15px;
  background: rgba(112, 62, 106, 0.11);
  width: 40px;
  height: 30px;
  text-align: center;
  color: #703E6A;
  font-weight: bold;
  font-size: 15px;
  padding-top: 6px;
`;

const LeftIcon = styled.div`
`;

const New = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #703E6A;
  margin-right: 10px;
`;

const ChatResult = (props) => {

  const {
    roomId,
    isAgent,
    companyId,
    label,
    userId,
    showUnreadCount = false,
    active = false,
    message,
    creationTimestamp,
    createdAt,
    companyInfo,
    onClick = () => null,
    theme
  } = props;

  const unreadCount = useSelector(getUnreadMessagesCount(roomId));

  function clickHandler() {

    if (!roomId) {
      onClick({id: isAgent ? userId : companyId, type: isAgent ? 'user' : 'company'});
    } else {
      onClick({id: roomId, type: 'room'});
    }

  }

  const subtitle = useSelector(getCompanySubtitle(companyId));

  return (
    <Wrapper isActive={active} onClick={clickHandler}>
      <Subwrapper>
      {(message && !roomId && !isAgent) || (!roomId && isAgent) ?
        <Left>
          {message && !roomId && !isAgent ?
            <LeftIcon><Icon name={'waiting'} size={{ value: 20, unit: 'px'}} /></LeftIcon>
          : null}
          {!roomId && isAgent ?
            <LeftIcon><New /></LeftIcon>
          : null}
          </Left>
        : null}
        <Center>
          { true ?
            <UpperText>{(creationTimestamp || createdAt) ? chatReadableDate(creationTimestamp || createdAt) : null}</UpperText>
          : ""}
          <TitleText color={theme.colors.primary}>{ReactHtmlParser(label)}</TitleText>
          { subtitle ?
            <LowerText>{ReactHtmlParser(subtitle)}</LowerText>
          : ""}
        </Center>
        <Right>
          {showUnreadCount && unreadCount > 0 ? <Count>{unreadCount}</Count> : null}
          {active && <Icon name="arrow-right-2" size={{value: 25, unit: 'px'}}/>}
        </Right>
      </Subwrapper>
    </Wrapper>
  );
  // return (
  //   <Wrapper onClick={clickHandler} active={active}>
  //     <Left>
  //       {message && !roomId && !isAgent ?
  //         <LeftIcon><Icon name={'waiting'} size={{ value: 20, unit: 'px'}} /></LeftIcon>
  //       : null}
  //       {!roomId && isAgent ?
  //         <LeftIcon><New /></LeftIcon>
  //       : null}
  //       <LeftText>
  //         <TitleText>
  //           {ReactHtmlParser(label)}<br />
  //         </TitleText>
  //         {creationTimestamp ? chatReadableDate(creationTimestamp) : null}
  //       </LeftText>
  //     </Left>
  //     <Right>
  //       {showUnreadCount && unreadCount > 0 ? <Count>{unreadCount}</Count> : null}
  //       {!active && <Icon name="arrow-right-2" size={{value: 25, unit: 'px'}}/>}
  //     </Right>
  //   </Wrapper>
  // );
}

export default withTheme(ChatResult);
