import React, {Component} from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "../App/Button";
import Loader from "../Common/Loader";
import {sendMail} from "../../helpers";
import AnalyticsService from '../../services/AnalyticsService';
import {translations} from "../../locales";

const Wrapper = styled.div`
`;

const Row = styled.div`
  margin: 15px -15px;
  display: flex;
  align-items: center;
`;

const Col = styled.div`
  padding: 0 15px;
  ${({half}) => half ? 'width: 50%;' : ''}
  ${({half}) => !half ? 'flex: 1;' : ''}
  ${({align}) => align === 'right' ? 'text-align: right;' : ''}
  ${({align}) => align === 'center' ? 'text-align: center;' : ''}
`;

const Input = styled.input`
    display: block;
    background: #EDEDED;
    border: none;
    border-radius: 3px;
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
  `;

const Textarea = styled.textarea`
    display: block;
    background: #EDEDED;
    border: none;
    border-radius: 3px;
    width: 100%;
    padding: 10px 10px;
    font-size: 14px;
  `;

const Message = styled.div`
    background: #22b14c;
    padding: 15px;
    border-radius: 3px;
    color: #fff;
    margin: 30px 0;
`;

const Checkbox = styled.div`
  display: flex;
  > div {
    flex: 1;
    margin-left: 10px;
  }
  a:hover {
    text-decoration: underline;
  }
`

class ContactForm extends Component {

  state = {
    form: {
      name: '',
      email: '',
      message: '',
      privacyPolicy: false
    },
    recaptcha: null,
    sending: false,
    response: null
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReCAPTCHA = this.handleReCAPTCHA.bind(this);


  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.reset !== this.props.reset) {
      this.handleReset();
    }

  }

  handleChange(e) {

    // console.log(e.target);

    this.setState({
      form: Object.assign({}, this.state.form, {
        [e.target.name]: e.target.value
      })
    }, () => {
      // console.log(this.state);
    });

  }

  async handleSubmit(e) {
    e.preventDefault();

    let response;

    await this.setState({
      sending: true
    });

    try {
      response = await sendMail({
        fromEmail: this.state.form.email,
        fromName: this.state.form.name,
        message: this.state.form.message,
        reCaptcha: this.state.recaptcha,
        toEmail: this.props.toEmail,
        toName: this.props.toName,
        type: this.props.type,
        config: this.props.config,
        translations: this.props.translations
      });
    } catch (e) {
      console.error('error', e);
    }


    AnalyticsService.companyContacts({
      company: this.props.company,
      email: this.props.email
    });

    await this.setState({
      sending: false,
      response: true,
      form: {
        privacyPolicy: false,
        name: '',
        email: '',
        message: ''
      }
    })

  }

  handleReCAPTCHA(response) {
    this.setState({
      recaptcha: response
    })
  }

  handleReset() {

    this.setState({
      form: {
        privacyPolicy: false,
        name: '',
        email: '',
        message: ''
      },
      sending: false,
      response: null
    })

  }

  render() {

    const { translations, lang } = this.props
    const {recaptcha, sending, response} = this.state;
    const { privacyPolicy } = this.state.form;

    return (
      <Wrapper>
        {response && (<Message><div>{translations["UI"]["_contactform-messagesent"]}</div></Message>)}

        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col half>
              <Input placeholder={translations["UI"]["_contactform-name"]} name='name' value={this.state.form.name} type='text'
                     onChange={this.handleChange} required/>
            </Col>
            <Col half>
              <Input placeholder={translations["UI"]["_contactform-email"]} name='email' value={this.state.form.email} type='email'
                     onChange={this.handleChange} required/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Textarea rows={6} placeholder={translations["UI"]["_contactform-message"]} name='message' onChange={this.handleChange}
                        value={this.state.form.message} required/>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReCAPTCHA
                sitekey="6LfATGIUAAAAABdE2N1SJwR1xqkHj5uq9I6yX8Jm"
                onChange={this.handleReCAPTCHA}
                hl={this.props.lang}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Checkbox>
                <input onChange={this.handleChange} value="true" required name="privacyPolicy" type="checkbox" />
                <div><a target="_blank" href={`/${lang === 'it' ? 'it' : 'en'}/contact-policy`}>{translations['UI']['read_privacy_policy']}</a></div>
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col align={'center'}>
              {sending ? <Loader size={{value: 20, unit: 'px'}}/> :
                <Button fullSize={true} disabled={!privacyPolicy || !recaptcha} type='submit'>{translations["UI"]["Send message"]}</Button>}
            </Col>
          </Row>
        </form>
      </Wrapper>
    )
  }

}

export default ContactForm;
