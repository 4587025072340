import React, { useRef, useEffect } from 'react';
import styled from "styled-components";
import Message from "./Message";
import {useSelector} from "react-redux";
import {getMessages} from "../../../store/chat/selectors";

/* eslint-disable no-unused-expressions */

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  overflow: scroll;
`;

const Inner = styled.div`
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%; 
  // min-height: 100%;
  // display: flex;
  padding: 25px;
  // flex-direction: column;
`;

export default function Messages({ items = [], attendeeId }) {

  const wrapperRef = useRef(null);
  const innerRef = useRef(null);

  const scrollToBottom = () => {
    if (wrapperRef && innerRef) {
      wrapperRef.current.scrollTop = innerRef.current.scrollHeight - wrapperRef.current.clientHeight;
    }
  };

  useEffect(() => {
    scrollToBottom()
  }, [items]);

  return (
    <Wrapper ref={wrapperRef}>
      <Inner ref={innerRef}>
        {items.map((item, index) => <Message key={`chat-message-${index}`} {...item} self={item.senderId === attendeeId} />)}
      </Inner>
    </Wrapper>
  );
}

