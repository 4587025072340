import {channel} from 'redux-saga';
import {all, put, select, takeLatest, call} from 'redux-saga/effects'
import {
  archiveRoom,
  contactCompany, getCompanyActive,
  getProfile,
  init,
  listMessages, loadCompanyData,
  messageReceived,
  roomList,
  sendMessage,
  setAttendee,
  setCount,
  setCurrent,
  setFacets,
  setFilters,
  setFiltersTree,
  startRoom,
  waitingList
} from './actions'
import {getCompanyListData, getCount, getCurrent, getIsAgent, getPendingRooms, getRooms} from "./selectors";
import DataService from "../../services/DataService";
import {getLocale} from "../localization/selectors";
import {profile} from "../auth/actions";
import {pick, uniq, difference, capitalize, isArray} from 'lodash';
import {getRecords as updateBCards} from "../bcard/actions";
import {getToken} from "../auth/selectors";
import {getChatConfig, getConfig, getTenant} from "../settings/selectors";

let instance;
let connection;

const listMessagesTriggerChannel = channel();
const setAttendeeChannel = channel();
const messageReceivedChannel = channel();
const profileChannel = channel();

const roomsListTriggerChannel = channel();
const waitingListTriggerChannel = channel();
const loadCompanyDataChannel = channel();
const loadCompanyDataSuccessChannel = channel();

const COMPANY_INFO_LIST = [
  'id',
  'logo',
  'slug',
  'name',
  'identity',
  'owner',
  'foundation',
  'typology',
  'labels',
  'brands',
  'tastings',
  'otherInfo',
  'events',
  'lowertext'
];

async function getCompanyInfo(companyId) {

  let companyInfo = null;
  let response = await DataService.getItemById("companies", companyId, false);
  if (response) {
    companyInfo = {
      id: response.id,
      logo: response.options.logo.src,
      slug: response.id,
      name: response.options.name,
      identity: null,
      foundation: response.options.foundationYear,
      typology: null,
      labels: null,
      brands: response.options.brands,
      tastings: null,
      otherInfo: null,
      events: [],
      lowertex: response.options.name,
    }
  }
  return Promise.resolve(companyInfo);

}

async function getRoomsCompanyInfo(companyIds) {
  return Promise.all(companyIds.map(companyId => getCompanyInfo(companyId)));
}

function* initSaga({payload}) {

  const chatConfig = yield select(getChatConfig);
  const tenantId = yield select(getTenant);

  let url = 'wss://f87t5bgbg3.execute-api.eu-west-1.amazonaws.com/vrf/';
  let pluginEnv = 'production';

  if (chatConfig && window && window.location) {
      const key = Object.keys(chatConfig).find(key => key === window.location.hostname);
      if (key) {
        url = chatConfig[key].wsEndpoint;
        pluginEnv = chatConfig[key].pluginEnv;
      } else {
        url = chatConfig.default.wsEndpoint;
        pluginEnv = chatConfig.default.pluginEnv;
      }
  }

  const language = yield select(getLocale);
  const user = payload;
  const {accessToken} = user;

  if (user) {

    instance = new window.ChatClient({
      url,
      tenantId
    });

    instance.use(window.ChatClientPluginVrf, {
      env: pluginEnv
    });

    instance.getProfile(accessToken).then((response) => {
      const { roles, permissions, options } = response;
      let companyData = null;
      if (options && options.hasOwnProperty('premium')) {
        companyData = {
          activity: capitalize(options.premium.activity),
          activity2: isArray(options?.premium?.subactivity) ? options.premium?.subactivity.map(item => capitalize(item)) : null,
          name: options.premium.businessName,
        }
      }

      profileChannel.put(profile.trigger({
        permissions,
        roles,
        companyData
      }));
    });

    instance.subscribeToStateChange(async (response) => {

      const {waitingStatus, roomList, messages, attendeeInfo} = response;
      setAttendeeChannel.put(setAttendee.trigger(attendeeInfo));
      listMessagesTriggerChannel.put(listMessages.trigger(messages));

      if(response.attendeeInfo.hasOwnProperty('attendeeType') && response.attendeeInfo.attendeeType === 'USER') {
        let companyIds = [
          ...waitingStatus.map(item => item.companyId),
          ...Object.keys(roomList).map(roomId => roomList[roomId].companyId)
        ];
        companyIds = uniq(companyIds);
        loadCompanyDataChannel.put(loadCompanyData.trigger(companyIds));
      }

    });

    instance.subscribeToEvent('NEW_MESSAGE', (message) => {
      messageReceivedChannel.put(messageReceived.trigger(message))
    });

    instance.subscribeToEvent('ROOM_LIST', ({ items }) => {
      roomsListTriggerChannel.put(roomList.trigger(items));
    });

    instance.subscribeToEvent('WAITING_LIST', ({ items }) => {
      waitingListTriggerChannel.put(waitingList.trigger(items));
    });

    instance.subscribeToEvent('WAITING_STATUS', ({ items }) => {
      waitingListTriggerChannel.put(waitingList.trigger(items));
    });

    instance.subscribeToEvent('ROOM_CLOSED', ({ items }) => {
      instance.listRooms();
      instance.getWaitingList();
      instance.getWaitingStatus();
    });
    instance.subscribeToEvent('CHAT_REJECT', ({ items }) => {
      instance.listRooms();
      instance.getWaitingList();
      instance.getWaitingStatus();
    });

    instance.connect({
      userId: user.id,
      nickname: `${user.firstname} ${user.lastname}`,
      language,
      token: accessToken
    }).then(() => {
      // instance.getProfile();
    }).catch(() => {
      // profileChannel.put(profile.trigger({
      //   permissions: [],
      //   roles: [],
      // }));
    });

    try {
      yield;
    } catch (e) {
      console.error('connection error');
    }

  }

}

function* sendMessageSaga({payload}) {

  const current = yield select(getCurrent);

  if (current.type === 'room') {

    instance.sendMessage({
      roomId: current.id,
      message: payload.message
    });

  } else {

    instance.contactCompany({
      companyId: current.id,
      message: payload.message
    });

  }

}

function* setCurrentSaga({payload}) {
  if (payload.type === 'room') {
    instance.listMessages({
      roomId: payload.id
    })
  }
}

function* listMessagesTriggerSaga({payload}) {
  yield put(listMessages.success(payload))
}

function* setAttendeeSaga({payload}) {
  yield put(setAttendee.success(payload))
}

function* startRoomSaga({payload}) {
  instance.beginChat({
    userId: payload.userId
  })
}

function* archiveRoomSaga({payload}) {
  if (payload.type === 'room') {
    instance.closeRoom({
      roomId: payload.id
    });
  } else {
    instance.rejectChat({
      userId: payload.id
    });
  }
}

function* contactCompanySaga({payload}) {

  let rooms = yield select(getRooms);
  let waitingRooms = yield select(getPendingRooms);
  const roomExists = rooms.find(room => room.hasOwnProperty('roomId') && room.roomStatus === 'ACTIVE' && room.companyId === payload.companyId);
  const waitingExists = waitingRooms.find(room => room.companyId === payload.companyId);
  if (!roomExists && !waitingExists) {
    waitingRooms = [...waitingRooms, ...[{label: payload.companyName, companyId: payload.companyId}]];
    yield put(loadCompanyData.trigger([payload.companyId]));
    yield put(waitingList.trigger(waitingRooms));
  } else if(roomExists) {
    yield put(setCurrent.trigger({
      type: 'room',
      id: roomExists.roomId,
      tab: 'active'
    }))
  }
}

function* messageReceivedSaga({payload}) {
  yield put(messageReceived.success(payload));
}

function* profileSaga({payload}) {
  yield put(profile.success(payload));
}

function* getProfileSaga() {

  const token = yield select(getToken);
  instance.getProfile(token.accessToken).then((response) => {
    const { roles, permissions } = response;
    profileChannel.put(profile.trigger({
      permissions,
      roles,
    }));
  });

}

function* roomsListTriggerSaga({ payload }) {
  const isAttendeeAgent = yield select(getIsAgent);
  payload = payload.map(item => ({...item, label: !isAttendeeAgent ? item.companyName : item.userNickname}));
  yield put(roomList.trigger(payload));
  yield put(updateBCards.trigger());
}

function* waitingListTriggerSaga({ payload }) {
  const isAttendeeAgent = yield select(getIsAgent);
  payload = payload.map(item => ({...item, label: !isAttendeeAgent ? item.companyName : item.userNickname}));
  yield put(waitingList.trigger(payload));
  yield put(updateBCards.trigger());
}

function* loadCompanyDataSaga({ payload }) {
  const alreadyLoaded = yield select(getCompanyListData);
  const loadData = difference(payload, alreadyLoaded);
  getRoomsCompanyInfo(loadData).then((response) => {
    const data = response.filter(item => item).reduce((acc, item) => ({...acc, [item.id]: item}), {});
    loadCompanyDataSuccessChannel.put(loadCompanyData.success(data));
  })
}

function* loadCompanyDataSuccessSaga({ payload }) {
  yield put(loadCompanyData.success(payload));
}

function* activeCompanySaga({ payload }) {

  let success = false;
  const token = yield select(getToken);

  try {
    success = yield call([instance, 'hasChatOperator'], payload, token.accessToken);
  } catch (e) {}

  yield put(getCompanyActive.success({ companyId: payload, success }));

}

export default function* root() {
  yield all([
    yield takeLatest([init.TRIGGER], initSaga),
    yield takeLatest([setCurrent.TRIGGER], setCurrentSaga),
    yield takeLatest([sendMessage.TRIGGER], sendMessageSaga),
    yield takeLatest([contactCompany.TRIGGER], contactCompanySaga),
    yield takeLatest([startRoom.TRIGGER], startRoomSaga),
    yield takeLatest([archiveRoom.TRIGGER], archiveRoomSaga),
    yield takeLatest(listMessagesTriggerChannel, listMessagesTriggerSaga),
    yield takeLatest(setAttendeeChannel, setAttendeeSaga),
    yield takeLatest(messageReceivedChannel, messageReceivedSaga),
    yield takeLatest(profileChannel, profileSaga),

    yield takeLatest(roomsListTriggerChannel, roomsListTriggerSaga),
    yield takeLatest(waitingListTriggerChannel, waitingListTriggerSaga),

    yield takeLatest([profile.TRIGGER], profileSaga),
    yield takeLatest([getProfile.TRIGGER], getProfileSaga),

    yield takeLatest([loadCompanyData.TRIGGER], loadCompanyDataSaga),
    yield takeLatest(loadCompanyDataChannel, loadCompanyDataSaga),
    yield takeLatest(loadCompanyDataSuccessChannel, loadCompanyDataSuccessSaga),

    yield takeLatest([getCompanyActive.TRIGGER], activeCompanySaga),

  ])
}
