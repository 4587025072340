import React from 'react';
import styled from "styled-components";
import {useTheme} from "../../../themes";

const Tabs = styled.div`
    display: flex;
    padding: ${props => props.mobile ? '10px 0 10px 0' : '10px 15px 10px 15px'};
    margin-top: 10px;
    ${props => props.styles}
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 15px;
  font-size: 11px;
  padding: 0px 10px 0px 10px;
  background-color: transparent;
  color: ${props => (!props.active ? props.color : props.activeColor)};
  background: ${props => (!props.active ? props.background : props.activeBackground)};
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
  height: 25px;
`;


export default function SidebarTabs({ current = 'pending', onTabClick = () => null, counts = { active:0, pending: 0, archived: 0}, mobile = false, labels = {
  active: 'Attive',
  pending: 'In attesa',
  archived: 'Archiviate'
}}) {

  const theme = useTheme();
  const { button } = theme.list.resultsTab;
  const buttonStyles = {
    background: button.normal.background,
    color: button.normal.color,
    activeBackground: button.active.background,
    activeColor: button.active.color,
  };

  return (
    <Tabs mobile={mobile}>
      <Tab {...buttonStyles} active={current === 'pending'} onClick={() => onTabClick('pending')}>{labels.pending} {counts.pending ? `(${counts.pending})` : ''}</Tab>
      <Tab {...buttonStyles} active={current === 'active'} onClick={() => onTabClick('active')}>{labels.active} {counts.active ? `(${counts.active})` : ''}</Tab>
      <Tab {...buttonStyles} active={current === 'archived'} onClick={() => onTabClick('archived')}>{labels.archived} {counts.archived ? `(${counts.archived})` : ''}</Tab>
    </Tabs>
  );

}
