import styled from "styled-components";
import {LAYOUT_SM, LAYOUT_XS} from "./../../../../settings";

export const Wrapper = styled.div`
  height: 46px;
  margin: ${props => !props.mobile ? '10px 15px 0 15px' : '10px 0 0 0;'};
  display: flex;

`;

export const SearchWrapper = styled.div`

  height: 46px;
  display: block;
  position: relative;
  border-radius: ${props => props.borderRadius.value + props.borderRadius.unit};
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: ${props => props.background};
`;

export const Toggle = styled.div`
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? 7 : 20}px;
`;

export const User = styled.div`
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
`;

export const SearchField = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 46px);
  height: 100%;
  border: 0;
  line-height: 46px;
  padding: 0 20px;
  font-size: 14px;
  background-color: transparent;
  color: ${props => props.textColor};
  outline: none;

  &:focus{
    outline: none;
  }

  &:placeholder{
    color: ${props => props.placeholderColor}
  }
`;
