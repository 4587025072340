import React from 'react';
import Chunk from './../../App/Chunk';
import Image from './../../App/Image';
import InfoRow from './../../App/InfoRow';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLocale, getTranslations} from "../../../store/localization/selectors";
import {getUser} from "../../../store/auth/selectors";
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  background: #bf392b;
  background-image: url(${props => props.pic});
  background-size: cover;

  font-size: 26px;
  text-align: center;
  line-height: 80px;
  color: #fff;

`;
const HeaderTitle = styled.div`
`;
const Name = styled.div`
  color: #7B3572;
  font-size: 16px;
  font-weight: 700;
`;
const Company = styled.div`
  color: #585858;
  font-size: 12px;
  font-weight: 700;
`;

export default function UserInfo ( { data, userPlaceholder }) {

  const translations = useSelector(getTranslations);
  const iniziali = `${data.name.charAt(0)}${data.surname.charAt(0)}`;

  return (
    <div>
      <Chunk open={true} collapsible={false}>
        <Header>
          <Avatar>
            {iniziali}
          </Avatar>
          <HeaderTitle>
            <Name>{data.fullname}</Name>
            {data?.organization ? <Company>{data?.organization}</Company> : null}
          </HeaderTitle>
        </Header>
        {/* data?.nickname ?
          <InfoRow
            label={'Nickname'}
            value={data.nickname}
          />
        : null */}
        {data?.name ?
          <InfoRow
            label={translations['UI']['firstName']}
            value={data.name}
          />
          : null}
        {data?.surname ?
          <InfoRow
            label={translations['UI']['lastName']}
            value={data?.surname}
          />
          : null}
        {data?.telephone ?
          <InfoRow
            label={"Phone"}
            value={data.telephone}
          />
          : null}
        {data?.organization ?
          <InfoRow
            label={translations['UI']['company']}
            value={data.organization}
          />
          : null}
        {data?.country ?
          <InfoRow
            label={translations['UI']['nation']}
            value={data?.country.toUpperCase()}
          />
          : null}
        {data?.city ?
          <InfoRow
            label={"City"}
            value={data?.city}
          />
          : null}
        {data?.website ?
          <InfoRow
            label={"Website"}
            value={data?.website}
          />
          : null}
      </Chunk>
    </div>
  );

}
