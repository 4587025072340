import React from 'react';
import {Link} from 'react-router-dom';
import Chunk from './../../App/Chunk';
import Image from './../../App/Image';
import Button from './../../App/Button';
import InfoRow from './../../App/InfoRow';
import Result from './../../List/Sidebar/ResultsList/Result';
import Spacer from './../../App/Spacer';
import styled from "styled-components";
import {withTheme} from "../../../themes";

const Title = styled.div`
  color: ${({ color }) => color};
  font-size: 12px;
  font-weight: 700;
`;

const CompanyInfo =  ({ theme, lang, data, translations, showEvents }) => {

  return (
    <div>
      <Chunk open={true} collapsible={false}>
        {data.logo ?
          <center>
            <Image src={data.logo} width={{"value": 80, "unit": "%"}} height={{"value": 120, "unit": "px"}} fit={"contain"} alt="logo"/>
          </center>
          : null}
        <Spacer size={{value: 20, unit: "px"}}/>
        <center>
          <Link to={"/" + lang + "/companies/" + data.slug}>
            <Button display="inline-block"
                    variant={"default-o"}>{translations["sheet-product"]["_more-company-info"]}</Button>
          </Link>
        </center>
        <Spacer size={{value: 30, unit: "px"}}/>
        <Title color={theme.colors.primary}>{data.name}</Title>
        {data.identity && data.identity.length > 0 ?
          <InfoRow
            label={translations["sheet-company"]["Identity"]}
            value={data.identity.join(" ")}
          />
          : ""}
        {data.owner ?
          <InfoRow
            label={translations["sheet-company"]["Owner"]}
            value={data.owner}
          />
          : ""}
        {data.foundation ?
          <InfoRow
            label={translations["sheet-company"]["Foundation year"]}
            value={data.foundation}
          />
          : ""}
        {data.typology ?
          <InfoRow
            label={translations["sheet-company"]["Company typology"]}
            value={data.typology}
          />
          : ""}
        {data.labels ?
          <InfoRow
            label={translations["sheet-company"]["Number of labels produced"]}
            value={data.labels}
          />
          : ""}
        {data.brands && data.brands.length > 0 ?
          <InfoRow
            label={translations["sheet-company"]["Brands"]}
            value={data.brands.join(", ")}
          />
          : ""}
        {data.tastings > 0 ?
          <InfoRow
            label={translations["sheet-company"]["Open for tastings"]}
            value={data.tastings}
          />
          : ""}
        {data.otherInfo && data.otherInfo.length > 0 ?
          <InfoRow
            label={translations["sheet-company"]["Other information"]}
            value={data.otherInfo.join(", ")}
          />
          : ""}

        <Spacer size={{value: 30, unit: "px"}}/>

        {showEvents && data.events && data.events.length > 0 ?
          <>
            <Title>{translations["sheet-company"]["Events"]}</Title>
            {data.events.map((item, index) => (
              <Result
                key={index}
                slug={item.slug}
                item={item}
                url={"/" + lang + "/events/" + item.type + "/" + item.slug}
              />
            ))}
          </>
          : null}
      </Chunk>
    </div>
  );

}
export default withTheme(CompanyInfo);
