import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { withTheme } from './../../themes';
import Icon from "./../App/Icon"
import { getLayout } from './../../store/settings/selectors'
import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

const Wrapper  = styled.div`
  width: 100%;
  height: ${props => props.fullHeight ? "100%" : "auto"};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  background: ${props => props.background};
  border: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  box-shadow: ${props => props.boxShadow};
  overflow: hidden;

  transition: height 250ms ease-in-out;

  display: flex;
  flex-direction: column;
`;
const Bar  = styled.div`
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  background: ${props => props.background};
  color: ${props => props.textColor};

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props => props.isClickable ? "cursor: pointer;" : ""}

`;
const Content  = styled.div`
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border-top: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  background: ${props => props.background};
  color: ${props => props.textColor};

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  height: ${props => props.fullHeight ? "100%" : "auto"};
`;
const Arrow  = styled.div`
  transition: all 150ms ease-in-out;
  ${props => props.isRotated ? "transform: rotate(-180deg);" : ""}
`;

const Chunk = ({
  variant = "default",
  collapsible = true,
  open = true,
  title,
  children,
  theme,
  defaultOpened,
  fullHeight,
  noPadding
}) => {
  const layout = useSelector(getLayout);
  const [isOpen, setIsOpen] = useState(open);
  return (
    <Wrapper
      padding={noPadding ? null : theme.chunk.padding[layout]}
      background={theme.chunk.variant[variant].background}
      borderStyle={theme.chunk.borderStyle}
      borderSize={theme.chunk.borderSize}
      borderColor={theme.chunk.variant[variant].borderColor}
      borderRadius={theme.chunk.borderRadius}
      boxShadow={theme.chunk.variant[variant].boxShadow}
      isCollapsed={!isOpen}
      fullHeight={fullHeight}
    >
      {title || collapsible ?
        <Bar
          onClick={() => setIsOpen(collapsible ? !isOpen : true)}
          isClickable={collapsible}
          padding={noPadding ? null : theme.chunk.bar.padding[layout]}
          borderStyle={theme.chunk.bar.borderStyle}
          borderSize={theme.chunk.bar.borderSize}
          textFont={theme.chunk.bar.textFont}
          textSize={theme.chunk.bar.textSize}
          textWeight={theme.chunk.bar.textWeight}
          textLineHeight={theme.chunk.bar.textLineHeight}
          textLetterSpacing={theme.chunk.bar.textLetterSpacing}
          textTransform={theme.chunk.bar.textTransform}
          textColor={theme.chunk.variant[variant].bar.textColor}
          background={theme.chunk.variant[variant].bar.background}
        >
          {title}

          { collapsible ?
            <Arrow isRotated={isOpen}>
              <Icon
                size={{
                 value: 18,
                 unit: "px"
                }}
                name={theme.icons.set.chunk}
                color={theme.chunk.variant[variant].bar.icon.normal.color}
                background={theme.chunk.variant[variant].bar.icon.normal.background}
              />
            </Arrow>
          : "" }
        </Bar>
      : "" }
      {isOpen ?
        <Content
          padding={noPadding ? null : theme.chunk.content.padding[layout]}
          borderStyle={theme.chunk.content.borderStyle}
          borderSize={theme.chunk.content.borderSize}
          borderColor={theme.chunk.variant[variant].content.borderColor}
          textFont={theme.chunk.content.textFont}
          textSize={theme.chunk.content.textSize}
          textWeight={theme.chunk.content.textWeight}
          textLineHeight={theme.chunk.content.textLineHeight}
          textLetterSpacing={theme.chunk.content.textLetterSpacing}
          textTransform={theme.chunk.content.textTransform}
          textColor={theme.chunk.variant[variant].content.textColor}
          background={theme.chunk.variant[variant].content.background}
          fullHeight={fullHeight}
        >
          {children}
        </Content>
      : "" }
    </Wrapper>
  )
}

Chunk.propTypes = {
  variant: PropTypes.string,
  collapsible: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  fullHeight: PropTypes.bool,
  noPadding: PropTypes.bool
}

export default withTheme(Chunk)
